import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {

  @Input()
  public availablePagesAmount!: number;

   public pagesRange: number = 5;

  @Input()
  public currentPageIndex: number = 0;

  @Output()
  public changePage = new EventEmitter<any>();

  constructor(
  ) {}

  public availablePages(): number[] {
    const pages = [];
    this.pagesRange  = this.availablePagesAmount + 1;
    if (this.availablePagesAmount < this.pagesRange) {
      for (let i = 0; i < this.availablePagesAmount; i++) {
        pages.push(i);
      }
      return pages;
    }

    if (this.currentPageIndex === 0) {
      for (let i = 0; i < this.pagesRange; i++) {
        if (this.currentPageIndex + i >= 0 && this.currentPageIndex + i <= this.availablePagesAmount - 1) {
          pages.push(this.currentPageIndex + i);
        }
      }
    } else {
      for (let i = -1; i < this.pagesRange - 1; i++) {
        if (this.currentPageIndex + i >= 0 && this.currentPageIndex + i <= this.availablePagesAmount - 1) {
          pages.push(this.currentPageIndex + i);
        }
      }
    }

    return pages;
  }

  public onPreviousPageClick(): void {
    const availablePages = this.availablePages();
    const availableAmount = this.availablePagesAmount;

    if (availablePages.length > 1) {
      if ((this.currentPageIndex - 1) < 0) {
        this.currentPageIndex = availableAmount - 1;
      } else {
        this.currentPageIndex--;
      }
    }

    this.changePage.emit(this.currentPageIndex + 1);
  }

  public onNextPageClick(): void {
    const availablePages = this.availablePages();

    if (availablePages.length > 1) {
      if (this.currentPageIndex + 1 === this.availablePagesAmount) {
        this.currentPageIndex = 0;
      } else {
        this.currentPageIndex++;
      }
    }

    this.changePage.emit(this.currentPageIndex + 1);
  }

  public onSelectPage(pageIndex: number): void {
    this.currentPageIndex = pageIndex;
    this.changePage.emit(this.currentPageIndex + 1);
  }
}
