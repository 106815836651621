import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import { environment  } from '../../environments/environment'
import { IAccountInterface } from '@interface/account.modal';
import { IContactInterface } from '@interface/contact.modal'
import {IUser} from "@interface/user.modal";
import {LocalStorageService} from "@services/local-storage.service";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private _userData!: IUser;
  constructor(
    private _httpClient: HttpClient,
  ) { }

  public User(): Observable<IUser> {
    let res = new Subject<IUser>();
    if (!this._userData) {
      this.getUserDetails().subscribe(response => {
        // @ts-ignore
        this._userData = response[0];
        res.next(this._userData);
      });
    } else {
      res.next(this._userData);
    }
    return res.asObservable();
  }

  public getUserDetails(): Observable<IContactInterface> {
    // @ts-ignore
    const user = JSON.parse(localStorage.getItem('user')) || {};
    const url = `https://msgx-api-dev.appy.co.ao/api/Users/users/${user.userId}`
    return this._httpClient.get<IContactInterface>(url);
  }
  public updateAccountDetails(accountId: string, body: IAccountInterface): Observable<IAccountInterface> {
    const url = `${environment.apiUrl}/accounts/${accountId}`
    return this._httpClient.patch<IAccountInterface>(url, body);
  }
}
