import { AfterViewInit, Component, EventEmitter, Input, Output} from '@angular/core';
import { InputModel } from "@interface/input.model";
import { IInputTypeEnum } from "@enums/input.enum"
@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements AfterViewInit{
  @Output() public changeEvent = new EventEmitter<any>();
  @Output() public selectIndex = new EventEmitter<any>();
  @Input() public inputConfig: InputModel = {
    placeHolder: "placeHolder",
    label: "label",
    types: "input",
    inputType: "text"
  };
  @Input() public modalData: any;
  @Output() public modalDataChange = new EventEmitter<any>();
  @Input() public isMultiple: boolean = false;
  @Input() public options:
    Array<{name: string, value: string | number}> =
    [
      {name: "01", value: "01"}
    ];
  @Input() public selectedOption: Array<any> = []
  public inputTypeEnum = IInputTypeEnum;
  constructor() { }
  ngAfterViewInit(): void {
   setTimeout(() => {

     if(this.selectedOption.length && this.inputConfig.types === "select" && this.isMultiple) {
       this.modalData = [];
       this.selectedOption.forEach(ele => {
         const index = this.options.findIndex(option => option.value === ele.value);
         if (index > - 1 && ele.isSelected){
           this.modalData.push( this.options[index].value)
         }
       })
     }
   }, 1000)
  }
  public updateEvent(event: any): void {
    this.changeEvent.emit(event);
    this.modalDataChange.emit(this.modalData);
  }


  emitSelectIndex(event: any, i: number) {
    this.selectIndex.emit({event: event.source.selected, index: i})
  }
  change() {
    this.modalDataChange.emit(this.modalData);
  }


}
