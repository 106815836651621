import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalStorageService } from '@services/local-storage.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { APP_LANGUAGE, PASSWORD_RESET_PROFILE } from '../constants/constants';
import { UserService } from '@services/user.service';
import {microSoftSettings} from "../../environments/environment";

@Component({
  selector: 'app-log-in',
  templateUrl: './log-in.component.html',
  styleUrls: ['./log-in.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LogInComponent implements OnInit {
  constructor(
    private _fb: FormBuilder,
    private _router: Router,
    private _localStorageService: LocalStorageService,
    private _userService: UserService,
    private _oAuthService: OAuthService,
  ) {}

  public ngOnInit(): void {
    if (this._router.url.indexOf('error_description=AADB2C90118') > -1) {
      this.redirectUserToLoginB2C();
    } else {
      this._oAuthService
        .tryLoginImplicitFlow()
        .then((res) => {
          this.checkAccount();
        })
        .catch((err) => {
          this._oAuthService.initLoginFlow();
        });
    }
  }

  redirectUserToLoginB2C() {
    window.location.href =
      `https://${microSoftSettings.tenantName}.b2clogin.com/${microSoftSettings.tenantName}.onmicrosoft.com/oauth2/v2.0/authorize?` +
      `p=${PASSWORD_RESET_PROFILE}` +
      `&client_id=${microSoftSettings.clientid}` +
      `&nonce=defaultNonce` +
      `&redirect_uri=${window.location.origin}/login` +
      `&scope=openid` +
      `&response_type=id_token` +
      `&prompt=login` +
      `&ui_locales=${APP_LANGUAGE}`;
  }

  public checkAccount(): void {
    const claims: any = this._oAuthService.getIdentityClaims();
    if (claims) {
      const adminPermission = ['PPMVI', 'PPMUP', 'PPMCR', 'PPMAP', 'PMVI', 'PMCR', 'PMAP', 'PPMCR'];
      const mapperPermission = ['PPMVI', 'PPMUP', 'PPMCR', 'PMVI', 'PMCR'];
      const isAdmin = adminPermission.every((item) => claims.permissions.includes(item));
      const isMapper = mapperPermission.every((item) => claims.permissions.includes(item));
      const userinfo = {
        name: claims.name,
        userKey: claims.userKey,
        email: claims.emailId,
        isBusiness: claims.isBusiness,
        permissions: claims.permissions,
        role: isAdmin ? 'admin' : isMapper ? 'mapper' : '',
        userId: claims.userId
      };
      this._localStorageService.setUser(userinfo, null);
      this._router.navigate(['dashboard'])
    } else {
      this._oAuthService.initLoginFlow();
    }
  }
}
