<div class='button-position' [ngClass]='buttonPosition'>
  <ng-container *ngIf='buttonType === buttonTypeEnum.PRIMARY'>
    <button mat-raised-button color="primary" [disabled]='isLoader' (click)='emitButtonEvent(buttonType)'>
      <span  class="d-flex align-items-center">{{buttonText | translate}}
      <mat-spinner *ngIf="isLoader"></mat-spinner>
    </span></button>
  </ng-container>
  <ng-container *ngIf='buttonType === buttonTypeEnum.DANGER'>
    <button mat-raised-button color="warn" [disabled]='isLoader' (click)='emitButtonEvent(buttonType)'>
      <span  class="d-flex align-items-center">{{buttonText | translate}}
      <mat-spinner *ngIf="isLoader"></mat-spinner>
    </span></button>
  </ng-container>
  <ng-container *ngIf='buttonType === buttonTypeEnum.BUTTON_WITH_ICON'>
    <button mat-raised-button color="primary" [disabled]='isLoader' (click)='emitButtonEvent(buttonType)' class='primary-dark'>
      <div class='d-flex align-items-center' [ngClass]='{"flex-row-reverse right-border" : iconPosition === "right", "left-border": iconPosition === "left"} ' >
        <mat-icon class='material-icons-outlined' [ngClass]='{"px-3" : iconPosition === "right"}'>{{iconName}}</mat-icon>
        <span class="d-flex align-items-center">{{buttonText | translate}}
          <mat-spinner *ngIf="isLoader"></mat-spinner>
        </span>
      </div>
    </button>
  </ng-container>
  <ng-container  *ngIf='buttonType === buttonTypeEnum.BORDER'>
    <button mat-stroked-button [disabled]='isLoader' color="primary" (click)='emitButtonEvent(buttonType)'>
      <span class='border-button d-flex align-items-center'>{{buttonText | translate}}
        <mat-spinner *ngIf="isLoader"></mat-spinner>
      </span>
    </button>
  </ng-container>
</div>


