import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { ContactComponent } from './pages/contact/contact.component';
import { CampaignComponent } from './pages/custom-field/campaign.component';
import { LogsComponent } from './pages/logs/logs.component';
import { MessageComponent } from './pages/message/message.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { UserComponent } from './pages/user/user.component';
import { ScheduleComponent } from './pages/schedule/schedule.component'
import { EditScheduleComponent } from './pages/schedule/edit-schedule/edit-schedule.component'
import { AddCustomFieldComponent } from './pages/custom-field/add-custom-field/add-custom-field.component'
import {AuthGuard} from "./guards/auth.guard";
import {LogInComponent} from "./log-in-page/log-in.component";
import {AppComponent} from "./app.component";
import {DashboardComponent} from "./pages/dashboard/dashboard.component";

const routes: Routes = [
  {
    path: 'signin-oidc',
    component: LogInComponent,
  },
  {
    path: 'dashboard',
    component: AppComponent,
    children: [{
      path: '',
      component: DashboardComponent,
    }],
    canActivate: [AuthGuard]
  },
  {
    path: "contact",
    component: ContactComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "custom-field",
    component: CampaignComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "custom-field/:id",
    component: AddCustomFieldComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "logs",
    component: LogsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "template",
    component: MessageComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "template/:id",
    component: MessageComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "profile",
    component: ProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "users",
    component: UserComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "users/:id",
    component: UserComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "schedule",
    component: ScheduleComponent,
    canActivate: [AuthGuard]
  },
  {
    path: "schedule/:id",
    component: EditScheduleComponent,
    canActivate: [AuthGuard]
  },

  {
    path: "**",
    redirectTo: "signin-oidc"
  }
]

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
