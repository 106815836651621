<ng-container *ngIf='inputConfig.types === inputTypeEnum.INPUT'>
  <ng-container *ngIf='inputConfig.label'>
    <label class='label'>{{ inputConfig.label | translate }}</label>
  </ng-container>
  <input (keyup)="change()" [placeholder]="inputConfig.placeHolder | translate" class='input' [min]='inputConfig?.minLength' [max]='inputConfig?.maxLength' [(ngModel)]='modalData' [type]='inputConfig.inputType'>
</ng-container>

<ng-container *ngIf='inputConfig.types === inputTypeEnum.SELECT'>
    <ng-container *ngIf='inputConfig.label'>
    <mat-label class='label'>{{ inputConfig.label | translate }}</mat-label>
    </ng-container>
    <mat-select [(ngModel)]='modalData' (ngModelChange)='updateEvent(modalData)' class='input' [placeholder]='inputConfig.placeHolder | translate' [multiple]='isMultiple'>
      <mat-option [value]="item?.value" *ngFor='let item of options; index as i' (onSelectionChange)="emitSelectIndex($event, i)">{{item.name | translate}}</mat-option>
    </mat-select>
</ng-container>

<ng-container *ngIf='inputConfig.types === inputTypeEnum.TEXTAREA'>
  <ng-container *ngIf='inputConfig.label'>
    <label class='label'>{{inputConfig.label | translate}}</label>
  </ng-container>
  <textarea (keyup)="change()" [(ngModel)]='modalData' class="input" [placeholder]='inputConfig.placeHolder | translate' rows='6' ></textarea>
</ng-container>

<ng-container *ngIf='inputConfig.types === inputTypeEnum.PAGE_SELECTOR'>
  <select [(ngModel)]='modalData' (ngModelChange)='updateEvent(modalData)' class='selector'>
    <option *ngFor='let number of [10,20,30,40,50]' [value]='number'>{{number}}</option>
  </select>
</ng-container>
