import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
})
export class TabsComponent {
  @Input() tabsData: Array<{label: string, count: number}> = [];
  @Output() public tabsEvent = new EventEmitter<MatTabChangeEvent>();
  constructor() { }

  updateTabsEvent(event: MatTabChangeEvent) {
    this.tabsEvent.emit(event)
  }
}
