import { IContactItems } from "@interface/contact.modal";

export class ContactValidation {
  public static checkContactValidation(contactObj: IContactItems) {
    if(!contactObj.FirstName) {
      return "First Name Is Required"
    } else if (!contactObj.LastName) {
      return "Last Name Is Required"
    } else if (!contactObj.ID_Gender) {
      return "Gender is Required"
    } else if (!contactObj.MobilephoneNumber) {
      return "Mobile Number Is Required"
    } else if (!contactObj.CustomFields) {
      return "Custom Fields Is Required"
    } else if (!contactObj.DOB) {
      return "Date Of Birth Is Required"
    } else if (!contactObj.LanguageISO) {
      return "Language Is Required"
    } else if (!contactObj.CountryISO) {
      return "Country is Required"
    }
    return "successfully";
  }
}
