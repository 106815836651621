import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IUserInterface } from '@interface/user.modal'

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  public userEdit: boolean = false;
  public userTable = {
    rows : [
      {
        name: "USER_ID",
        value: "ID_Users"
      },
      {
        name: "FIRST_NAME",
        value: "FirstName"
      },
      {
        name: "LAST_NAME",
        value: "LastName"
      },
      {
        name: "EMAIL",
        value: "Email"
      },
      {
        name: "COUNTRY_ISO",
        value: "CountryISO"
      },
      {
        name: "DOB",
        value: "DOB"
      },
      {
        name: "ACTION",
        type: "action"
      }
    ]
  };
  public userData: Array<IUserInterface> = [
    {
      "ID_Users": 1,
      "FirstName": "Derson",
      "LastName": "Andrade",
      "Email": "derson.andrade@appy.co.ao",
      "MobilephonePrefix": "244",
      "MobilephoneNumber": "929443750",
      "CountryISO": "AO",
      "LanguageISO": "pt-pt",
      "DOB": "1981-02-13",
      "AgeRange": 3,
      "ID_Gender": 1,
      "ID_UserType": 1,
      "ID_Account": 2,
      "Enabled": true,
      "CreatedDate": "2022-03-07T19:16:52.907",
      "UpdatedDate": "2022-03-07T19:16:52.907"
    }
  ];
  constructor(
    readonly route: Router
  ) { }

  ngOnInit(): void {
    if(this.route.routerState.snapshot.url === "/users/add") {
      this.userEdit = true;
    }
  }
  public userEditEvent(): void {
    this.userEdit = true;
    this.route.navigate(["/users", "add"]).catch()
  }
}
