<section class='edit-schedule'>
  <div class='row m-0'>
    <div class='col-md-5 p-0'>
      <img src='assets/images/Bitmap.png' class='h-100 w-100 object-fit-cover' alt=''>
    </div>
    <div class='col-md-7 p-0'>
      <mat-card class='w-100 h-100'>
        <mat-card-title>
          <div class="d-flex justify-content-between">
            <div>
              <h1 class='mb-0'>{{"SET_SCHEDULER_FOR" | translate}} ( {{schedulerData[0].SchedullerTitle}} ) </h1>
              <P class='subtitle'>{{"ADD_TEMPLATE_MESSAGE" | translate}}</P>
            </div>
            <img src='assets/images/close.png' height='24' width='24' alt='' [mat-dialog-close]='true'>
          </div>
        </mat-card-title>
        <mat-card-content>
          <div class='row'>
            <div class='col-md-12'>
              <app-input [inputConfig]='{placeHolder: "NAME", types: "input", label: "NAME"}' [(modalData)]='schedulerData[0].SchedullerTitle'></app-input>
            </div>
            <div class='col-md-6 mt-3'>
              <app-input [inputConfig]='{placeHolder: "RECURRENCE_TYPE", types: "select", label: "RECURRENCE_TYPE"}' [(modalData)]='schedulerData[0].ID_RecurrenceType' [options]='recurrenceType' (changeEvent)='selectEvent($event)'></app-input>
            </div>
            <div class='col-md-6 mt-3'>
              <app-input [inputConfig]='{placeHolder: "SELECT_DAY", types: "select", label: "SELECT_DAY"}' [(modalData)]='schedulerData[0].DaysOfWeek' [options]='dayOfWeeks' ></app-input>
            </div>
            <div class='col-md-12 mt-3'>
              <app-input [inputConfig]='{placeHolder: "SELECT_TEMPLATE", types: "select", label: "SELECT_TEMPLATE"}' [(modalData)]='schedulerData[0].ID_Message' [options]='selectTemplateType' ></app-input>
            </div>
            <div class='col-md-4 mt-3'>
              <mat-label class="label">{{"START_DATE" | translate}}</mat-label>
              <div  class='recurrenceType0 d-flex'>
                <input class='input w-100' matInput [matDatepicker]="startDate" (click)='startDate.open()' [(ngModel)]="schedulerData[0].StartDate">
                <mat-datepicker-toggle matSuffix [for]="startDate"></mat-datepicker-toggle>
                <mat-datepicker #startDate></mat-datepicker>
              </div>
            </div>
            <div class='col-md-4 mt-3'>
              <mat-label class="label">{{"END_DATE" | translate}}</mat-label>
              <div  class='recurrenceType0 d-flex'>
                <input class='input w-100' matInput [matDatepicker]="endDate" (click)='endDate.open()' [(ngModel)]="schedulerData[0].EndDate" [min]="schedulerData[0].StartDate">
                <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle >
                <mat-datepicker #endDate></mat-datepicker>
              </div>
            </div>
            <div class='col-md-4 mt-3'>
              <mat-label class="label">{{"SELECT_TIME" | translate}}</mat-label>
              <div class='recurrenceType0 d-flex align-items-center'>
                <input class='input w-100' [owlDateTime]="dt1" [owlDateTimeTrigger]="dt1" [(ngModel)]="schedulerData[0].SendTime" >
                <mat-icon class='material-icons-outlined icon icon-delete' >calendar_month</mat-icon>
                <owl-date-time #dt1></owl-date-time>
              </div>
            </div>


            <div class='col-md-12 mt-3'>
              <mat-label>{{"FILTER" | translate }}</mat-label>
              <textarea (keydown)="getTextAreaEvent($event)" class="w-100 text-area" rows="8" [innerHTML]="FilterClauseConditions | prettyPrint" >
                </textarea>
            </div>
            <div class="col-6 mt-3">
              <div class="d-flex align-items-center h-100" *ngIf="filterCount">
                {{"FILTER_COUNT" | translate}}: {{filterCount}}
              </div>
            </div>

          </div>
        </mat-card-content>
        <div class="d-flex justify-content-center pb-3 pt-3">
          <app-button [buttonPosition]='"right"' class="me-3" [buttonText]='"RUN_NOW"' [buttonType]='"border"' (buttonEvent)='emitEvent()'></app-button>
          <app-button [buttonPosition]='"right"' [buttonText]='"SAVE_SCHEDULE"' [buttonType]='"primary"' (buttonEvent)="createSchedule()"></app-button>
        </div>
      </mat-card>
    </div>
  </div>
</section>
