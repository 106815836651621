import { Output, HostListener, Directive, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appDrag]',
})
export class DragAndDropDirective {
  @Output() public fileDropped = new EventEmitter();
  @HostListener('dragover', ['$event'])
  public onDragOver(evt: DragEvent): void {
    evt.preventDefault();
    evt.stopPropagation();
  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave(evt: DragEvent): void {
    evt.preventDefault();
    evt.stopPropagation();
  }

  @HostListener('drop', ['$event'])
  public onDrop(evt: DragEvent): void {
    evt.preventDefault();
    evt.stopPropagation();
    let files = evt?.dataTransfer?.files;
    if (files && files.length > 0) {
      this.fileDropped.emit(files)
    }
  }
}
