import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PowerBIService {

  constructor(private http: HttpClient){}

  public GetAzureAccessToken(tenantId: string, clientId: string, clientSecret: string, scope: string[]): Observable<OauthAccessTokenViewModel> {
    const url = 'https://app-appysaude-api-dev.azurewebsites.net/v1.0/analyticsAzureAuthToken'
    const headers = new HttpHeaders({
      'clientId': clientId, //this._AESEncryptDecryptService.encrypt(clientId),
      'clientSecret': clientSecret, //this._AESEncryptDecryptService.encrypt(clientSecret),
      'tenantId': tenantId, //this._AESEncryptDecryptService.encrypt(tenantId),
      'scope': scope
    });

    const options = {
      headers: headers,
    };

    return this.http.get(url, options)
    .pipe(
      map((response: any) => {
        if(response !== undefined && response !== null) {
          return response;
        }
      })
    );
  }

  public GetPowerBIEmbeddedReport(azureAuthToken: string, accessLevel: string, groupId: string, reportId: string): Observable<PowerBIEmbeddedReportViewModel> {
    const headers = new HttpHeaders({
      // 'Authorization': `Bearer ${azureAuthToken}`,
      'Content-Type': 'application/json'
    });
    const options = {
      "headers": headers
    };
    const url = `https://api.powerbi.com/v1.0/myorg/groups/${groupId}/reports/${reportId}/GenerateToken`;

    return this.http.post(url, {"accessLevel": accessLevel}, options)
    .pipe(
      map((response ) => {
        if(response === undefined || response === null) {
          throw('PowerBi Embedded report ERROR');
        }
        let report = new PowerBIEmbeddedReportViewModel();
        // @ts-ignore
        report.dataContext = response['@odata.context'];
        // @ts-ignore
        report.expiration = response['expiration'];
        // @ts-ignore
        report.token = response['token'];
        // @ts-ignore
        report.tokenId = response['tokenId'];

        return report;
      })
    )

  }

  public GetUsersRoles(userKey: string, language: string){//: Observable<Root>{
    const url = `https://app-appysaude-api-test.azurewebsites.net//v1.0/users/${userKey}/roles`
    const headers = new HttpHeaders({"Accept-Language": language});

    const options = {
      headers: headers,
    };

    return this.http.get(url, options)
    .pipe(
      map((response: any) => {
        if(response !== undefined && response !== null) {
          return response.items[0];
        }
      })
    );
  }
}

export class PowerBIEmbeddedReportViewModel {
  dataContext!: string
  expiration!: string
  token!: string
  tokenId!: string
}

export class OauthAccessTokenViewModel{
  token_type!: string
  expires_In!: string
  ext_expires_In!: string
  access_token!: string
  claims!: {
    auth_time: string
    emailId: string
    name: string
    permissions: string
    healthEstablishments: string
    userId: string
    extendedClaims: string
    userKey: string
    isBusiness: string
    accountType: string
    utid: string
  }
}

export class Root {
  items!: Item;
  totalCount!: number;
  pageSize!: number;
  currentPage!: number;
  totalPages!: number;
  hasMorePages!: boolean;
}

export class Item {
  userKey!: string;
  roles!: Role[];
  establishments!: Establishment[];
}

export class Role {
  roleId!: number;
  role!: string;
  serviceModuleId!: number;
  serviceModule!: string;
  permissions!: Permission[];
}

export class Establishment {
  healthEstNumber!: string;
}

export class Permission {
  roleFeaturePermissionId!: number;
  feature!: string;
  permission!: string;
  code!: string;
}
