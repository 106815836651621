import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from '@services/message.service'
import {IMessageTemplateItems} from '@interface/message.modal'

@Component({
  selector: 'app-message-template-list',
  templateUrl: './message-template-list.component.html',
  styleUrls: ['./message-template-list.component.scss']
})
export class MessageTemplateListComponent implements OnInit {
  public searchString = "";
  public searchOption: Array<string> = []
  public breadcrumbsUrl: Array<{name: string, url: string}> = [
    {
      name: "HOME",
      url: "/dashboard"
    },
    {
      name: "WHATSAPP_MESSAGE_TEMPLATE",
      url: "/template"
    }
  ];
  public availablePagesAmount!: number;
  @Output() public buttonEvent = new EventEmitter<any>();
  contactListPageConfig = {
    pageNumber: 1,
    pageSize: 10
  }
  public whatsappTable = {
    rows : [
      {
        name: "NAME",
        value: "TemplateName"
      },
      {
        name: "MESSAGE_TEXT",
        value: "Message"
      },
      {
        name: "CATEGORY_NAME",
        value: "CategoryName"
      },
      {
        name: "ACTION",
        type: "action",
        isSendButton: true,
        isScheduleButton: true,
      }
    ]
  };
  public whatsappData: Array<IMessageTemplateItems> = []
  constructor(
    readonly route : Router,
    readonly messageService: MessageService,
  ) { }

  ngOnInit(): void {
    this.getAllMessageTemplate()

  }

  public emitButtonEvent(event: any): void {
    this.route.navigate(["/template", "add"]).catch()
    this.buttonEvent.emit(event);
    this.messageService.templateData.next({});
  }

  public getAllMessageTemplate(): void {
    this.messageService.getMessageTemplates(this.contactListPageConfig.pageNumber, this.contactListPageConfig.pageSize, this.searchString).subscribe(res => {
      this.availablePagesAmount = res.totalPages;
      this.whatsappData = res.items;
      res.items.forEach(element => {
        this.searchOption.push(element.TemplateName)
      })
    })
  }

  public getTableEvent(event: any): void {
    if (event.type === "edit") {
      this.route.navigate(["/template", event.data.ID_MessageTemplates]).catch();
      this.messageService.templateData.next(event);
    } else if (event.type === "schedule") {
      this.route.navigate(["/schedule/add"]).catch();
    }
  }


  public onPageChange(pageNumber: number): void {
    this.contactListPageConfig.pageNumber = pageNumber;
    this.getAllMessageTemplate();
  }

  public onPageLimitChange(pageSize: number): void {
    this.contactListPageConfig.pageNumber = 1;
    this.contactListPageConfig.pageSize = pageSize;
    this.getAllMessageTemplate();
  }

  public  getSearchEvent(event: any) {
    this.contactListPageConfig.pageNumber = 1;
    this.contactListPageConfig.pageSize = 10;
    this.searchString = event;
    this.getAllMessageTemplate();
  }
}
