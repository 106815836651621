import {Component, OnInit} from '@angular/core'
import { MenuService } from '@services/menu.service';
import { LanguageService } from '@services/language.service';
import { TranslateService } from '@ngx-translate/core';
import {NullValidationHandler, OAuthService} from "angular-oauth2-oidc";
import {authConfig} from "./configes/auth.config";
import {UtilService} from "@services/util.service";
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
	title = 'appy-messagingx-app';
  constructor(
    private oauthService: OAuthService,
    readonly menuService: MenuService,
    private translate: TranslateService,
    private langService: LanguageService,
    readonly utilService: UtilService,
  ) {
    this.configureAuthentication();
    this.setLanguage();
    menuService.setMenu();
  }

  private setLanguage(): void {
    this.langService.setLanguage('pt');
    this.translate.use('pt');
  }
  private configureAuthentication(): void {
    this.oauthService.configure(authConfig);
    this.oauthService.setupAutomaticSilentRefresh();
    this.oauthService.tokenValidationHandler = new NullValidationHandler();
  }

  ngOnInit(): void {
    this.utilService.getLanguage();
  }
}
