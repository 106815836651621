<ng-container *ngIf='!userEdit'>
  <section class='user'>
    <div class='container-fluid'>
      <div class='row'>
        <div class='col-md-6'>
          <div class='user-list'>
            <app-heading [title]='"USERS_LIST"'></app-heading>
          </div>
        </div>
        <div class='col-md-6'>
          <div class='add-user-btn'>
            <app-button (buttonEvent)='userEditEvent()' [buttonText]='"ADD_USER"' [buttonType]='"buttonWithIcon"' [buttonPosition]='"right"' [iconPosition]='"right"' [iconName]='"add"'></app-button>
          </div>
        </div>
        <div class='col-md-12'>
          <div class='user-tables'>
            <app-table [table]='userTable' [data]='userData'></app-table>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-container>
<ng-container *ngIf='userEdit'>
  <app-add-user></app-add-user>
</ng-container>
