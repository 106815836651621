export class ScheduleValidation {
  public static checkScheduleValidation(contactObj: any) {
    if (!contactObj.SchedullerTitle) {
      return "PLEASE_ADD_TITLE";
    } else if (!contactObj.StartDate) {
      return "PLEASE_SELECT_START_DATE"
    } else if (!contactObj.EndDate) {
      return "PLEASE_SELECT_END_DATE"
    } else if (!contactObj.SendTime) {
      return "PLEASE_SELECT_TIME"
    } else if (contactObj.ID_RecurrenceType === 2 && !contactObj.DaysOfWeek) {
      return "PLEASE_SELECT_WEEK_OF_DAY"
    } else if (contactObj.ID_RecurrenceType === 3 && !contactObj.DayOfMonth) {
      return "PLEASE_SELECT_DAY"
    } else if (contactObj.ID_RecurrenceType === 4 && !contactObj.MonthOfYear) {
      return "PLEASE_SELECT_MONTH"
    }
    return true
  }
}
