<a [routerLink]='' [queryParams]='filter' class='sidebar-link' routerLinkActive='sidebar-link-active'
   (click)='hideSidebar()'>
  <li class='sidebar-item'>
    <mat-icon class='sidebar-link-icon material-icons-outlined'  *ngIf='icon'>{{icon}}</mat-icon>
    <p class='sidebar-link-title'>
      <ng-content></ng-content>
      <span class='badge sidebar-link-badge d-flex justify-content-center align-items-center' *ngIf='newLink'>
          <span>New</span>
      </span>
    </p>
  </li>
</a>
