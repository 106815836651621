import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment  } from '../../environments/environment'
import { IAccountInterface } from '@interface/account.modal';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(
    private _httpClient: HttpClient,
  ) { }

  public getAccountDetails(accountId: string): Observable<IAccountInterface> {
    const url = `${environment.apiUrl}/accounts/${accountId}`
    return this._httpClient.get<IAccountInterface>(url);
  }
  public updateAccountDetails(accountId: string, body: IAccountInterface): Observable<IAccountInterface> {
    const url = `${environment.apiUrl}/accounts/${accountId}`
    return this._httpClient.patch<IAccountInterface>(url, body);
  }
}
