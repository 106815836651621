import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['../input/input.component.scss']
})
export class SearchComponent implements OnInit {
  searchControl = new FormControl();
  @Input() Text: string = "";
  @Output() public searchEvent = new EventEmitter<any>();
  @Input() options: string[] = ['One', 'Two', 'Three'];
  filteredOptions!: Observable<string[]>;
  constructor() { }

  ngOnInit(): void {

    this.filteredOptions = this.searchControl.valueChanges.pipe(
      startWith(''),
      map((value: string) => this.filter(value)),
    );
  }

  emitSearchEvent(event: any, isClick: boolean) {
    if(event.key === "Enter" || isClick  ) {
      this.searchEvent.emit(event?.target?.value ? event.target.value : event)
    } else if (event.key === "Backspace") {
      this.searchEvent.emit(event?.target?.value)
    }
  }
  private filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }
}
