import { Component, Input, OnInit } from '@angular/core'
import { MessageService } from '@services/message.service'

@Component({
  selector: 'app-edit-message-template',
  templateUrl: './edit-message-template.component.html',
  styleUrls: ['./edit-message-template.component.scss']
})
export class EditMessageTemplateComponent implements OnInit{
  public templateObj: any = {}
  public templateOption: Array<{name: string, value: number}> = [];
  public templateLanguage: Array<{name: string, value: string}> = [
    {name: "English", value: "English"},
    {name: "Portuguese", value: "pt-pt"},
    {name: "Dutch", value: "Dutch"},
  ];
  constructor(
    readonly messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.getMessageCategoriesTemplates();
    this.messageService.templateData.subscribe((data: any) => {
      if(data.data) {
        this.templateObj = data.data
      }
    })
  }
  public getAllMessageTemplate(): void {
    this.messageService.getMessageCategories("1").subscribe(res => {})
  }

  public getMessageCategoriesTemplates(): void {
    this.messageService.getMessageCategoriesTemplates().subscribe(res => {
      res.items.forEach(template => {
        const obj = {
          name: template.CategoryName,
          value: template.ID_MessageCategories,
          ...template
        }
        this.templateOption.push(obj)
      })
    })
  }

  public createMessageTemplate(): void {
  }

}
