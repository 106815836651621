import { Injectable } from '@angular/core';
import { IMenuHeader } from '@interface/menu.model';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  public EnableChannels: Array<IMenuHeader> = [];
  constructor() {}

  public setMenu() {
    this.EnableChannels = [
      {
        name: "HOME",
        isChild: false,
        routerLink: "/"
      },
      {
        name: "CONTACTS",
        isChild: true,
        routerLink: "/contact",
        childLink: [
          {
            name: "CUSTOM_FIELD",
            routerLink: "/custom-field",
          },
        ]
      },
      {
        name: "MESSAGES",
        isChild: false,
        routerLink: "/template"
      },
      {
        name: "SCHEDULE",
        isChild: false,
        routerLink: "/schedule"
      },
      {
        name: "LOGS",
        isChild: false,
        routerLink: "/logs"
      },
    ]
  }
}
