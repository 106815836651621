<div class="m-30 position-relative">
  <div class="modal-header" *ngIf="data.isShowHeader">
    <p>{{ data.title | translate }}</p>
    <mat-icon class="material-icons move-close" (click)="dismissModal()"> close </mat-icon>
  </div>
  <div class="d-flex justify-content-center">
    <div class="dialog-content">
      <ng-template [ngTemplateOutlet]="data.template"></ng-template>
    </div>
  </div>
</div>
