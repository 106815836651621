<section class='whatsapp-template position-relative' xmlns='http://www.w3.org/1999/html'>
  <div class='container-fluid'>
    <div class='row'>
      <div class='col-md-6'>
        <app-heading [title]='"WHATSAPP_MESSAGE_TEMPLATE"'></app-heading>
      </div>
      <div class='col-md-6'>
        <app-breadcrumbs [navigationData]='breadcrumbsUrl'></app-breadcrumbs>
      </div>
      <div class='col-md-12'>
       <div class='d-flex justify-content-between mt-2'>
         <div class="d-flex align-items-center">
           <div class='contact-search w-100 me-3'>
             <app-search [Text]='"SEARCH"' [options]='searchOption' (searchEvent)="getSearchEvent($event)"></app-search>
           </div>
           <div class='d-flex justify-content-between justify-content-md-start'>
             <div class='page-selector'>
               <app-input [inputConfig]='{placeHolder: "", types: "pageSelector"}' [modalData]='contactListPageConfig.pageSize' (changeEvent)="onPageLimitChange($event)"></app-input>
             </div>
           </div>
         </div>
         <app-button [buttonType]='"buttonWithIcon"' [iconName]='"add"' [buttonText]='"NEW_MESSAGE_TEMPLATE"'  [buttonPosition]='"left"' (buttonEvent)='emitButtonEvent($event)'></app-button>
       </div>
      </div>
    </div>


  </div>
  <div class='bg-gray'>
    <div class='container-fluid'>
      <div class='row'>
        <div class='col-md-12'>
          <div class='wp-table'>
            <app-table [table]='whatsappTable' [data]='whatsappData' (tableEvent)='getTableEvent($event)' (pageChangeEvent)="onPageChange($event)" [availablePagesAmount]="availablePagesAmount"></app-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

