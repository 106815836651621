import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs'
import { environment } from '../../environments/environment';
import {
  ICreateTemplate,
  IMessageCategories,
  IMessageCategoriesInterface,
  IMessageTemplatesInterface
} from '@interface/message.modal'
import {UtilService} from "@services/util.service";

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  public templateData = new BehaviorSubject({});
  public isEditMessage =  false;
  constructor(
    private _httpClient: HttpClient,
    private utilService: UtilService
  ) {
  }

  public getMessageCategories(messageTemplateId: string): Observable<Array<IMessageCategoriesInterface>> {
    const url = `${environment.apiUrl}/senders/${this.utilService.getSenderId()}/messageTemplates/${messageTemplateId}`
    return this._httpClient.get<Array<IMessageCategoriesInterface>>(url);
  }

  public getMessageTemplates(pageNumber: number, pageSize: number, search: string): Observable<IMessageTemplatesInterface> {
    let url = "";
    if(search) {
      url = `${environment.apiUrl}/senders/${this.utilService.getSenderId()}/messageTemplates?q=TemplateName='${search}'&pageNumber=${pageNumber}&pageSize=${pageSize}`;
    } else {
      url = `${environment.apiUrl}/senders/${this.utilService.getSenderId()}/messageTemplates?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    }
    return this._httpClient.get<IMessageTemplatesInterface>(url);
  }

  public createMessageTemplates(messageData: ICreateTemplate): Observable<ICreateTemplate> {
    const url = `${environment.apiUrl}/senders/${this.utilService.getSenderId()}/messageTemplates`
    return this._httpClient.post<ICreateTemplate>(url, messageData);
  }

  public updateMessageTemplates(messageTemplateId: string, messageData: ICreateTemplate): Observable<ICreateTemplate> {
    const url = `${environment.apiUrl}/senders/${this.utilService.getSenderId()}/messageTemplates/${messageTemplateId}`
    return this._httpClient.patch<ICreateTemplate>(url, messageData);
  }

  public getMessageCategoriesTemplates(): Observable<IMessageCategories> {
    const url = `${environment.apiUrl}/messageCategories`
    return this._httpClient.get<IMessageCategories>(url);
  }

}
