import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment  } from '../../environments/environment';
import { ICreateCustomFieldInterface, ICustomFieldInterface } from '@interface/custom-field.modal'

@Injectable({
  providedIn: 'root'
})
export class CustomFieldService {
  constructor(
    private _httpClient: HttpClient,
  ) { }

  public getAllCustomFieldList(isAll: boolean, pageNumber?: number, pageSize?: number, search?: string): Observable<ICustomFieldInterface> {
    let url = "";
    if(search && !isAll) {
      url = `${environment.apiUrl}/customFields?q=Name='${search}'&pageNumber=${pageNumber}&pageSize=${pageSize}`
    } else {
      url =  `${environment.apiUrl}/customFields`
    }
    return this._httpClient.get<ICustomFieldInterface>(url);
  }
  public createCustomFieldList(body:ICreateCustomFieldInterface,  contactId: string): Observable<ICreateCustomFieldInterface> {
    const url = `${environment.apiUrl}/contacts/${contactId}/customFields`
    return this._httpClient.post<ICreateCustomFieldInterface>(url, body);
  }
  public updateCustomFieldList(body:ICreateCustomFieldInterface,  contactId: string): Observable<ICreateCustomFieldInterface> {
    const url = `${environment.apiUrl}/contacts/${contactId}/customFields`
    return this._httpClient.post<ICreateCustomFieldInterface>(url, body);
  }
}
