<section class='campaign'>
  <div class='container-fluid'>
    <div class='row mt-3'>
      <div class='col-md-6'>
        <app-heading [title]='"CUSTOM_FIELD"' [fontSize]='"19px"'></app-heading>
      </div>
      <div class='col-md-6'>
        <app-breadcrumbs [navigationData]='breadcrumbsUrl'></app-breadcrumbs>
      </div>

    </div>
    <div class='row mt-2'>
      <div class='col-md-4'>
        <div class="d-flex align-items-center">
          <div class='contact-search w-100 me-3'>
            <app-search [Text]='"SEARCH"' [options]='searchOption' (searchEvent)="getSearchEvent($event)"></app-search>
          </div>
          <div class='d-flex justify-content-between justify-content-md-start'>
            <div class='page-selector'>
              <app-input [inputConfig]='{placeHolder: "", types: "pageSelector"}' [modalData]='customPageListPageConfig.pageSize' (changeEvent)="onPageLimitChange($event)"></app-input>
            </div>
          </div>
        </div>
      </div>
      <div class='col-md-3 col-4 my-3 my-md-0'>
        <div class='icons d-flex ms-4'>
          <div class='export-button'>
            <mat-icon class='material-icons-outlined' (click)='print()' [matTooltip]="'PRINT' | translate ">print</mat-icon>
          </div>
          <div class='share'>
            <mat-icon class='material-icons-outlined' (click)='exportCSV()' [matTooltip]="'EXPORT' | translate ">ios_share</mat-icon>
          </div>
        </div>
      </div>
      <div class='col-md-5 col-8 my-3 my-md-0'>
        <div class='d-flex justify-content-md-end justify-content-end'>
          <app-button [buttonType]='"buttonWithIcon"' [iconName]='"add"' [buttonText]='"ADD_CUSTOM_FIELD"' (buttonEvent)='AddCustomField()'></app-button>
        </div>
      </div>
    </div>
  </div>
<!--  <div class='col-6 col-md-3'>-->
<!--    <app-input-->
<!--      [inputConfig]='{placeHolder: "10", types: "pageSelector"}'-->
<!--      [modalData]='"10"'-->
<!--    ></app-input>-->
<!--  </div>-->

  <div class='bg-gray'>
    <div class='container-fluid'>
      <div class='row'>
        <div class='col-md-12'>
          <div id='campaign-table'>
            <app-table [table]='campaignTable' [data]='campaignTableData' [availablePagesAmount]="availablePagesAmount"></app-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
