import { Component } from '@angular/core';
import {UtilService} from "@services/util.service";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent  {
  public senders: any = [];
  public senderID: number = 1;
  constructor(
    readonly utilService: UtilService,
  ) {
    this.getSender();
  }

  private getSender() {
     this.utilService.getSender().subscribe(
       (res: any) => {
         this.senders = res.items;
         if(res.items.length) {
           localStorage.setItem('senderID', res.items[0].ID_Senders);
         }
       }
     );
  }

 public setSenderID(event: any) {
   localStorage.setItem('senderID', event);
  }
}
