import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { LocalStorageService } from '@services/local-storage.service';
import { UserService } from '@services/user.service';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _localService: LocalStorageService,
    private _userService: UserService
    ) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    const user = this._localService.currentUser;
    if (user) {
      return true;
    } else {
      this._router.navigate(['/signin-oidc']).catch();
      return false;
    }
  }
}
