import { Component, EventEmitter, Input, Output } from '@angular/core';
import {ModalComponent} from "../modal/modal.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent {
  dialogRef: any;
  rowIndex!: number;
  @Output() public tableEvent = new EventEmitter<any>();
  @Output() public pageChangeEvent = new EventEmitter<any>();
  @Input() data: any = [];
  @Input() availablePagesAmount!: number;
  @Input() table: any = {
    rows: [{
      type: "",
      name: "",
      isSendButton: false
    }],
  };
  constructor(
    readonly dialog: MatDialog
  ) {

  }

  emitEvent(rule: any, rowIndex: number, actionType?: string) {
    if (actionType) {
      this.tableEvent.emit({index: rowIndex, data: rule, type: actionType})
    } else {
      this.tableEvent.emit({index: rowIndex, data: rule})
    }
  }

  onPageChange(pageNumber: number): void {
    this.pageChangeEvent.emit(pageNumber);
  }

  public openDetailsModal(template: any, index: number): void {
    this.rowIndex = index;
    this.dialogRef = this.dialog.open(ModalComponent, {
      data: { title: "DETAILS", template, isShowHeader: true },
      height: "auto",
      width: "90%",
      panelClass: "custom-modalBox"
    });
  }
}
