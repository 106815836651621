<section class='profile'>
  <div class='container-fluid'>
    <div class='px-3 row justify-content-center'>
      <div class='col-lg-6 profile-card'>
        <div class='row'>
          <div class='col-md-12'>
            <div class='profile-input d-flex align-items-center'>
              <label>{{ "NAME" | translate }} </label>
              <input type='text' [placeholder]='"NAME" | translate' class='input ' [(ngModel)]='profileData.Name'/>
            </div>
            <div class='profile-input d-flex align-items-center'>
              <label>{{ "COUNTRY_ISO" | translate }} </label>
              <mat-select [(ngModel)]='profileData.CountryISO' class='input' [placeholder]='"COUNTRY_ISO" | translate'>
                <mat-option [value]="item?.value" *ngFor='let item of profileLanguage'>{{item?.name}}</mat-option>
              </mat-select>
            </div>
            <div class='profile-input d-flex align-items-center'>
              <label>{{ "BRAND_COLOUR" | translate }} </label>
              <div class='color-main position-relative d-flex align-items-center'>
                <div
                  [cpOutputFormat]="'hex'"
                  [colorPicker]="profileData.BrandColour"
                  (colorPickerChange)='getColor($event)'
                  class='color-pointer'
                  [ngStyle]='{"background": profileData.BrandColour}'>
                </div>
                <mat-checkbox class="ms-3" [(ngModel)]='isShowTitle'>{{ "SHOW_TITLE" | translate }}</mat-checkbox>
              </div>
            </div>

            <div class='upload-logo'>
              <input type='file' accept='image/png, image/jpeg' class='d-none' #fileUpload (change)='fileUploadEvent($event, "upload")'>
              <div class='droppable-area' appDrag (fileDropped)='fileUploadEvent($event, "drag")'>
                <div class='upload-area d-flex align-items-center p-3 justify-content-between' (click)='fileUpload.click()'>
                  <div class='d-flex flex-column justify-content-center align-items-center text-center'>
                    <div class='mb-2'>{{ 'ASPECT_RATIO' | translate }} : 181 * 39</div>
                    <div class='select-btn'>
                      <app-button [buttonType]='"primary"' [buttonText]='"SELECT_FROM_COMPUTER"'></app-button>
                    </div>
                    <div>{{ 'OR' | translate }}</div>
                    <div class='drag-label'>{{ 'DRAG_IT_HERE' | translate }}</div>
                  </div>
                  <div class='image-preview d-flex align-items-center' >
                    <ng-container *ngIf='profileData.LogoURL  '>
                      <img [src]='profileData.LogoURL' class='img-fluid' alt='logo' width='100'>
                    </ng-container>
                    <ng-container *ngIf='isShowTitle && profileData.Name.length'>
                      <p class='logo-title ms-2'>{{profileData.Name}}</p>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
            <div class='profile-input d-flex align-items-center'>
              <label>{{"ACCOUNT_KEY" | translate}} </label>
              <div class='with-icon position-relative w-100'>
                <input readonly type='text' [placeholder]='"ACCOUNT_KEY" | translate' class='input ' [ngModel]='profileData.AccountKey'/>
                <mat-icon class='material-icons-outlined' (click)='copyToClipboard(profileData.AccountKey)'>content_copy</mat-icon>
              </div>
            </div>
            <div class='profile-input d-flex align-items-center'>
              <label>{{"AUTH_TOKEN" | translate}} </label>
              <div class='with-icon position-relative w-100'>
                <input readonly type='text' [placeholder]='"AUTH_TOKEN" | translate' class='input ' [ngModel]='profileData.AuthToken'/>
                <mat-icon class='material-icons-outlined' (click)='copyToClipboard(profileData.AuthToken)'>content_copy</mat-icon>
              </div>
            </div>
          </div>
          <div class='col-md-12'>
            <app-button [buttonType]='"primary"' [buttonText]='"SAVE_PROFILE"' [buttonPosition]='"right"' (buttonEvent)='updateAccountProfile()'></app-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
