<section class='add-user'>
  <div class='container-fluid'>
    <div class='row justify-content-center'>
      <div class='col-lg-6'>
        <mat-card>
          <mat-card-title>
            <app-heading [title]='"ADD_USER"'></app-heading>
          </mat-card-title>
          <mat-card-content>
            <div class='row'>
              <div class='col-md-6 mb-3'>
                <app-input [inputConfig]='{placeHolder: "FIRST_NAME", types: "input", label: "FIRST_NAME"}'></app-input>
              </div>
              <div class='col-md-6 mb-3'>
                <app-input [inputConfig]='{placeHolder: "LAST_NAME", types: "input", label: "LAST_NAME"}'></app-input>
              </div>
              <div class='col-md-12 mb-3'>
                <app-input [inputConfig]='{placeHolder: "EMAIL", types: "input", label: "EMAIL", inputType: "email"}'></app-input>
              </div>
              <div class='col-md-3 mb-3'>
                <app-input [inputConfig]='{placeHolder: "PHONE_PREFIX", types: "select", label: "PHONE_PREFIX"}' [options]='phonePrefix'></app-input>
              </div>
              <div class='col-md-9 mb-3'>
                <app-input [inputConfig]='{placeHolder: "CONTACT_NUMBER", types: "input", label: "CONTACT_NUMBER", inputType: "number"}'></app-input>
              </div>
              <div class='col-md-4 mb-3'>
                <app-input [inputConfig]='{placeHolder: "COUNTRY_ISO", types: "select", label: "COUNTRY_ISO"}' [options]='userLanguage'></app-input>
              </div>
              <div class='col-md-4 mb-3'>
                <app-input [inputConfig]='{placeHolder: "LANGUAGE_ISO", types: "select", label: "LANGUAGE_ISO"}' [options]='languageISO'></app-input>
              </div>
              <div class='col-md-4 mb-3'>
                <app-input [inputConfig]='{placeHolder: "GENDER", types: "select", label: "GENDER"}' [options]='genderOption'></app-input>
              </div>
              <div class='col-md-12 mt-3'>
                <app-button [buttonType]='"primary"' [buttonPosition]='"right"' [buttonText]='"ADD_USER"'></app-button>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</section>
