import { Component, OnInit } from '@angular/core';
import { ICampaignStatusItem } from '@interface/campaign.modal';
import { UtilService } from '@services/util.service';
import {LogsService} from "@services/logs.service";

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements OnInit {
  logsStatuses: Array<ICampaignStatusItem> = [];
  public availablePagesAmount! : number;
  public logsTable = {
    rows : [
      {
        name: "BODY",
        value: "Body"
      },
      {
        name: "AMOUNT",
        value: "Amount"
      },
      {
        name: "VIEWS",
        value: "ID_MessageScheduller"
      },
      {
        name: "FROM",
        value: "From"
      },
      {
        name: "TO",
        value: "To"
      },
      {
        name: "SEND_DATE",
        value: "SentDate"
      },
      {
        name: "ERROR_CODE",
        value: "ErrorCode"
      },
      {
        name: "STATUS",
        value: "status"
      }
    ]
  };
  logsListPageConfig = {
    pageNumber: 1,
    pageSize: 10
  }
  public breadcrumbsUrl: Array<{name: string, url: string}> = [
    {
      name: "HOME",
      url: "/dashbooard"
    },
    {
      name: "LOGS_LIST",
      url: "/logs"
    }
  ];
  public searchOption: Array<string> = []
  public logsTableData: Array<{
    name: string,
    budget: string,
    views: string,
    clicks: string,
    start_date: string,
    end_date: string,
    status: string
  }> = [];
  public searchString = "";
  constructor(
    readonly UtilService: UtilService,
    readonly LogsService: LogsService
  ) { }

  ngOnInit(): void {
    this.getLogs();

    const logsStatusesObj = {
      name: "All",
      value: "all",
    }
    this.logsStatuses.splice(0, 0, logsStatusesObj);
  }
  public print(): void {
    this.UtilService.printElement('table');
  }
  public exportCSV(): void {
    this.UtilService.exportToCSV(this.logsTableData, "Campaign")
  }
  public getLogs(): void {
    this.logsTableData.length = 0;
    this.LogsService.getLogs( this.logsListPageConfig.pageNumber, this.logsListPageConfig.pageSize, this.searchString).subscribe(result => {
      this.availablePagesAmount = result.totalPages
      result.items.forEach((ele: any) => {
        ele.status = this.UtilService.getStatus(ele.ID_Status);
        this.logsTableData.push(ele);
        this.searchOption.push(ele.Body);
      })
      // this.logsTableData = result.items
    })
  }

  public onPageChange(event: any): void {
    this.logsListPageConfig.pageNumber = event;
    this.getLogs();
  }

  public onPageLimitChange(pageSize: number): void {
    this.logsListPageConfig.pageNumber = 1;
    this.logsListPageConfig.pageSize = pageSize;
    this.getLogs();
  }
  public getSearchEvent(event: any) {
    this.logsListPageConfig.pageNumber = 1;
    this.logsListPageConfig.pageSize = 10;
    this.searchString = event;
    this.getLogs();
  }
}
