import { AuthConfig } from 'angular-oauth2-oidc';
import { microSoftSettings } from 'src/environments/environment';
import {APP_LANGUAGE} from "../constants/constants";

export const authConfig: AuthConfig = {
  redirectUri: microSoftSettings.redirectUri,
  postLogoutRedirectUri: window.location.origin + '/',
  responseType: 'token id_token',
  issuer: `https://${ microSoftSettings.tenantName }.b2clogin.com/${ microSoftSettings.tenantid }/v2.0/`,
  strictDiscoveryDocumentValidation: false,
  tokenEndpoint: `https://${ microSoftSettings.tenantName }.b2clogin.com/appyb2c.onmicrosoft.com/oauth2/v2.0/authorize?ui_locales=${ APP_LANGUAGE }&p=${ microSoftSettings.signInPolicy }`,
  loginUrl: `https://${ microSoftSettings.tenantName }.b2clogin.com/appyb2c.onmicrosoft.com/oauth2/v2.0/authorize?ui_locales=${ APP_LANGUAGE }&p=${ microSoftSettings.signInPolicy }`,
  logoutUrl: `https://${ microSoftSettings.tenantName }.b2clogin.com/appyb2c.onmicrosoft.com/oauth2/logout?ui_locales=${ APP_LANGUAGE }&p=${ microSoftSettings.signInPolicy }`,
  clientId: `${microSoftSettings.clientid}`,
  scope: `openid profile https://${ microSoftSettings.tenantName }.onmicrosoft.com/${ microSoftSettings.clientid }/user_impersonation`,
  skipIssuerCheck: true,
  clearHashAfterLogin: false,
  oidc: true,
};
