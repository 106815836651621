<div class='search-main'>
  <mat-icon>search</mat-icon>
  <input type="text"
         [placeholder]='Text | translate'
         aria-label="Number"
         class='input icon'
         matInput
         [formControl]="searchControl"
         (keyup)='emitSearchEvent($event, false)'
         [matAutocomplete]="auto">
  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option" (click)="emitSearchEvent(option, true)">
      {{option}}
    </mat-option>
  </mat-autocomplete>
</div>
