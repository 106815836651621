import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';;

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  public user!: BehaviorSubject<any>;
  public userDetails!: Observable<any>;
  private _tokenClaims: BehaviorSubject<any>;
  public tokenClaims: Observable<any>;

  constructor() {
    // @ts-ignore
    if (JSON.parse(localStorage.getItem('user'))) {
      // @ts-ignore
      this.user = new BehaviorSubject<UserModel>(JSON.parse(localStorage.getItem('user')));
      this.userDetails = this.user.asObservable();
    }


    this._tokenClaims = new BehaviorSubject<any>(null);
    this.tokenClaims = this._tokenClaims.asObservable();

    window.onstorage = e => {
      if (e.key === '__storejs__') {
        // @ts-ignore
        const userString: string = e.storageArea.user;

        if (userString) {
          this._tokenClaims.next(JSON.parse(userString));
        }
      }
    };
  }

  public get<T>(key: string): T {
    return JSON.parse(localStorage.getItem(key) || 'null') as T;
  }

  public set<T>(key: string, data: T): void {
    const JSONString = JSON.stringify(data);

    localStorage.setItem(key, JSONString);
  }

  public remove(key: string): void {
    localStorage.removeItem(key);
  }

  public setUser(userdata: any, callback: any): void {
    localStorage.setItem('user', JSON.stringify(userdata));

    this.user.next(userdata);

    if (callback) {
      callback();
    }
  }
  public get currentUser() {
    if (this.user) {
      return this.user.getValue();
    } else {
      return null;
    }
  }

  public clearLocalStorage(): void {
    localStorage.clear();
  }
}
