import { NgModule } from "@angular/core";
import { InputComponent } from "./component/input/input.component";
import { ButtonComponent } from "./component/button/button.component";
import { ModalComponent } from "./component/modal/modal.component";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material.module'
import { HeaderComponent } from './component/header/header.component';
import { LanguageSelectorComponent } from './component/header/language-selector/language-selector.component';
import { ProfileWidgetComponent } from './component/header/profile-widget/profile-widget.component'
import {
  TopBarNavigationListComponent
} from './component/header/top-bar-navigation-list/top-bar-navigation-list.component';
import { SidebarLinkComponent } from './component/header/sidebar-link/sidebar-link.component'
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { TableComponent } from './component/table/table.component';
import { PaginationComponent } from './component/table/pagination/pagination.component';
import { LoaderComponent } from './component/loader/loader.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { SearchComponent } from './component/search/search.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { BreadcrumbsComponent } from  './component/breadcrumbs/breadcrumbs';
import { TabsComponent } from './component/tabs/tabs.component';
import { HeadingComponent } from './component/heading/heading.component';
import { DragAndDropDirective } from './directives/drag-and-drop.directive';
import { ProfileComponent } from './pages/profile/profile.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { TopBarSelectComponent } from './component/header/top-bar-select/top-bar-select.component'
import {PrettyPrintPipe } from "./pipe/prettyprint.pipe"
const commonComponent = [
  InputComponent,
  ButtonComponent,
  ModalComponent,
  HeaderComponent,
  TopBarNavigationListComponent,
  LanguageSelectorComponent,
  ProfileWidgetComponent,
  SidebarLinkComponent,
  TableComponent,
  PaginationComponent,
  LoaderComponent,
  SearchComponent,
  BreadcrumbsComponent,
  TabsComponent,
  HeadingComponent,
  ProfileComponent
];
const directives = [
  DragAndDropDirective,
  PrettyPrintPipe
]
@NgModule({
	declarations: [
		...commonComponent,
		...directives,
		TopBarSelectComponent
	],
    imports: [
        CommonModule,
        MaterialModule,
        FormsModule,
        RouterModule,
        TranslateModule.forRoot(),
        NgxUiLoaderModule,
        MatAutocompleteModule,
        ReactiveFormsModule,
        ColorPickerModule
    ],
  exports: [
    ...commonComponent,
    ...directives
  ],
  bootstrap: [
    ...commonComponent
  ]
})
export class SharedModule {}
