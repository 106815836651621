import {Injectable} from '@angular/core';
import html2canvas from 'html2canvas';
import { ExportToCsv } from 'export-to-csv';
import { NotifierServices } from '@services/notification.service'
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  IAgeRangeInterface,
  ICountryInterface,
  ICurrencyInterface,
  IGenderInterface,
  ILanguageInterface,
  ILogInterface
} from '@interface/utillities.modal'
import {TranslateService} from "@ngx-translate/core";
import { LogsStatusEnum } from "@enums/logsStatus.enum"
import {LanguageService} from "@services/language.service";
@Injectable({
  providedIn: 'root'
})
export class UtilService
{
  constructor(
    private _httpClient: HttpClient,
    readonly notificationService: NotifierServices,
    private translate: TranslateService,
    readonly langService: LanguageService
  ) {

  }
  public returnAge: Array<IAgeRangeInterface> = [] ;
  public genderOption: Array<IGenderInterface> = [] ;
  public phonePrefix: Array<ICountryInterface> = [] ;
  public userLanguage: Array<ILanguageInterface> = [] ;
  public languageISO: Array<ILanguageInterface> = [] ;
  public printElement(id: string): void {

    // @ts-ignore
    html2canvas(document.getElementById(id), {
      scrollX: 0,
      scrollY: 0,
    }).then((canvas) => {
      const dataUrl = canvas.toDataURL('image/png');
      let windowContent = '<!DOCTYPE html>';
      windowContent += '<html>';
      windowContent += '<body>';
      windowContent += '<img style="max-width:100vw;" src="' + dataUrl + '" alt=""> ';
      windowContent += '</body>';
      windowContent += '</html>';
      const printWin: Window | null = window.open('', '', 'width=900,height=600');
      printWin?.document.open();
      printWin?.document.write(windowContent);
      printWin?.document.close();
      printWin?.focus();
      setTimeout(() => {
        printWin?.print();
      }, 10);
      setTimeout(() => {
        printWin?.close();
      }, 100);
    });
  }


  public exportToCSV(data: any, name: string = 'List'): void {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      filename: name,
      title: name,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(data);
  }

  public copyToClipboard(value: string): void {
    const el = document.createElement("textarea");
    el.value = value;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    this.notificationService.showNotification("success", "Copy To Clipboard Successfully.")
  }
  public languageCode(language: string): Observable<ILanguageInterface[]> {
    const url = `${environment.azureBlobUrl}${language}/language.json`
    return this._httpClient.get<ILanguageInterface[]>(url);
  }

  public countryCode(language: string): Observable<ICountryInterface[]> {
    const url = `${environment.azureBlobUrl}${language}/country.json`
    return this._httpClient.get<ICountryInterface[]>(url);
  }

  public currencyCode(language: string): Observable<ICurrencyInterface> {
    const url = `${environment.azureBlobUrl}${language}/currency.json`
    return this._httpClient.get<ICurrencyInterface>(url);
  }

  public ageRange(language: string): Observable<IAgeRangeInterface[]> {
    const url = `${environment.azureBlobUrl}${language}/agerange.json`
    return this._httpClient.get<IAgeRangeInterface[]>(url);
  }

  public gender(language: string): Observable<IGenderInterface[]> {
    const url = `${environment.azureBlobUrl}${language}/gender.json`
    return this._httpClient.get<IGenderInterface[]>(url);
  }

  public logsStatus(language: string): Observable<ILogInterface> {
    const url = `${environment.azureBlobUrl}${language}/logstatus.json`
    return this._httpClient.get<ILogInterface>(url);
  }
  public getTranslate(text: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.translate.get(text).subscribe((res: string) => {
        resolve(res as string);
      });
    });
  }

  public getSender(): Observable<ILogInterface> {
    const url = `${environment.apiUrl}/senders`
    return this._httpClient.get<any>(url);
  }

  public getStatus(id: number): string {
   return LogsStatusEnum[id - 1];
  }

  public getSenderId(): number | null {
    if(localStorage.getItem("senderID")) {
      return parseInt(localStorage.getItem("senderID") || "");
    } else {
      return null;
    }
  }

  public getLanguage () {
    this.langService.currentLanguage.subscribe((lang: string) => {
       this.getAge(lang);
       this.getGender(lang);
       this.getCountryCode(lang);
       this.returnLanguageCode(lang);
    });
  }

  public getAge(language: string): any {
    if(!this.returnAge.length) {
      this.ageRange(language).subscribe(res => {
        this.returnAge = [];
        res.forEach(ele => {
          this.returnAge.push({
            name: ele.AgeRange,
            value: ele.ID_AgeRange,
            ...ele
          })
        })
      })
    }
  }

  public getGender(language: string): any {
    if(!this.genderOption.length) {
      this.gender(language).subscribe(res => {
        this.genderOption = [];
        res.forEach(ele => {
          this.genderOption.push({
            name: ele.Gender,
            value: ele.ID_Gender,
            ...ele
          })
        })
      })
    }
  }

  public getCountryCode(language: string): any {
    if(!this.phonePrefix.length || !this.userLanguage.length) {
      this.countryCode(language).subscribe(res => {
        this.phonePrefix = [];
        this.userLanguage = [];
        res.forEach(ele => {
          this.phonePrefix.push({
            name: `+ ${ele.CallingCode}`,
            value: ele.CallingCode,
            ...ele
          });
          this.userLanguage.push({
            name: ele.CountryISO,
            value: ele.CountryISO,
            ...ele
          });
        });
      })
    }

  }
  public returnLanguageCode(language: string): any {
    if(!this.languageISO.length) {
      this.languageCode(language).subscribe(res => {
        this.languageISO = [];
        res.forEach(ele => {
          this.languageISO.push({
            name: ele.LanguageISO,
            value: ele.LanguageISO,
            ...ele
          })
        })
      });
    }
  }
}
