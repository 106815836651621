import { Component, OnInit } from '@angular/core';
import codes from 'country-calling-code';
import codesISO from 'iso-language-codes'
@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserComponent implements OnInit {
  public phonePrefix: Array<{name: string, value: string}> = [];
  public userLanguage: Array<{name: string, value: string}> = [];
  public languageISO: Array<{name: string, value: string}> = [];
  public genderOption: Array<{name: string, value: string}> = [
    {
      name: "Male",
      value: "1"
    },
    {
      name: "Female",
      value: "2"
    },
    {
      name: "Other",
      value: "3"
    }
  ];
  constructor() { }

  ngOnInit(): void {
    this.setPrefix()
  }
  setPrefix() {
    codes.forEach(country => {
      this.phonePrefix.push({
        name: `+ ${country.countryCodes[0]}`,
        value: country.countryCodes[0]
      })
      this.userLanguage.push({
        name: country.isoCode2,
        value: country.isoCode2
      })
    });
    codesISO.forEach(languageISO => {
      this.languageISO.push({
        name: languageISO.iso639_1,
        value: languageISO.iso639_1
      })
    });
  }
}
