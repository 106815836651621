import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IButtonTypeEnum } from '@enums/button.enum';
@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {
  @Input() public buttonType: 'primary' | 'danger' | 'buttonWithIcon' | 'border' = "primary";
  @Input() public buttonText: string = "Primary";
  @Input() public buttonPosition: 'left' | 'right' | 'center' = "center";
  @Input() public iconPosition: 'left' | 'right' = 'left';
  @Input() public iconName: string = '';
  @Input() public isLoader: boolean = false;
  @Output() public buttonEvent = new EventEmitter<any>();
  public buttonTypeEnum = IButtonTypeEnum;
  constructor() { }


  emitButtonEvent(buttonType: string) {
    this.buttonEvent.emit(buttonType)
  }
}
