// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
  apiUrl: "https://msgx-api-dev.appy.co.ao/v1.0",
  azureBlobUrl: "https://stblobmessaginx001.blob.core.windows.net/asset/",
  azureLNGCODE: "123",
  apiKey: "1q2e21d122"
}
export const microSoftSettings = {
  tenantid: '90baac6b-f022-4793-9fd9-d764535d526a',
  clientid: '2df4f931-c4e8-4e84-b852-44a64501d0da',
  authority: 'https://login.microsoftonline.com/90baac6b-f022-4793-9fd9-d764535d526a',
  redirectUri: 'https://msgx-tst.appy.co.ao/signin-oidc',
  tenantName: 'appyb2c',
  signInPolicy: 'B2C_1A_YM_BA_SIGNIN_WEB_DEV',
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
