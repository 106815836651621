import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private _languageSubject: BehaviorSubject<string>;
  public currentLanguage: Observable<string>;
  public languageChanged = new Subject<string>();

  constructor(private translate: TranslateService) {
    let lang = localStorage.getItem('language');
    if(!lang)
      lang = 'pt';
    this._languageSubject = new BehaviorSubject<string>(lang);
    this.currentLanguage = this._languageSubject.asObservable();
  }

  public setLanguage(lang: string): void {
    localStorage.setItem('language', lang);
    this.translate.use(lang);
    this._languageSubject.next(lang);
    this.languageChanged.next(lang);
  }

  public get language(): string {
    return this._languageSubject.value;
  }
}
