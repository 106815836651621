<section class='schedule'>
  <div class='container-fluid'>
    <div class='row'>
      <div class='col-md-6'>
            <app-heading [title]='"SCHEDULE_LIST"' [fontSize]='"19px"'></app-heading>
      </div>
      <div class='col-md-6'>
        <app-breadcrumbs [navigationData]='breadcrumbsUrl'></app-breadcrumbs>
      </div>
    </div>
    <div class='row mt-2'>
      <div class='col-md-4'>
        <div class="d-flex align-items-center">
          <div class='contact-search w-100 me-3'>
            <app-search [Text]='"SEARCH"' [options]='searchOption' (searchEvent)="getSearchEvent($event)"></app-search>
          </div>
          <div class='d-flex justify-content-between justify-content-md-start'>
            <div class='page-selector'>
              <app-input
                [inputConfig]='{placeHolder: "10", types: "pageSelector"}'
                [modalData]='scheduleListPageConfig.pageSize' (changeEvent)="onPageLimitChange($event)"
              ></app-input>
            </div>
          </div>
        </div>
      </div>
      <div class='col-md-3 col-4 my-3 my-md-0'>
        <div class='icons d-flex ms-4'>
          <div class='export-button'>
            <mat-icon class='material-icons-outlined' (click)='print()' [matTooltip]="'PRINT' | translate ">print</mat-icon>
          </div>
          <div class='share'>
            <mat-icon class='material-icons-outlined' (click)='exportCSV()' [matTooltip]="'EXPORT' | translate ">ios_share</mat-icon>
          </div>
        </div>
      </div>
      <div class='col-md-5 col-8 my-3 my-md-0'>
        <div class='d-flex justify-content-md-end justify-content-end'>
          <app-button [buttonType]='"buttonWithIcon"' [iconName]='"add"' [buttonText]='"UPLOAD_CONTACT"' (buttonEvent)='editSchedule(); SchedulerID = "add"'></app-button>
        </div>
      </div>
    </div>
  </div>
  <div class='bg-gray'>
    <div class='container-fluid'>
      <div class='row'>
        <div class='col-md-12'>
          <div id='logs-table'>
            <app-table  [table]='scheduleTable' [data]='scheduleTableData' (pageChangeEvent)="onPageChange($event)" (tableEvent)='getScheduleEvent($event)' [availablePagesAmount]="availablePagesAmount"></app-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<ng-template #template>
  <div *ngIf="tableActionType === 'send'">
    <p class="my-3 text-center">{{"ARE_YOU_SURE_YOU_WANT_TO_SEND_SCHEDULE_NOW" | translate}}</p>
    <div class="d-flex align-items-center justify-content-center">
      <app-button [buttonPosition]='"center"' [buttonType]='"border"' [buttonText]='"CANCEL"' class="me-2" [mat-dialog-close]="true" (click)="isButtonLoader = false"></app-button>
      <app-button [buttonPosition]='"center"' [buttonType]='"primary"' [buttonText]='"RUN_SCHEDULE"' (buttonEvent)="runSchedule()" [isLoader]="isButtonLoader"></app-button>
    </div>
  </div>
  <div *ngIf="tableActionType === 'delete'">
    <p class="my-3 text-center">{{"ARE_YOU_SURE_YOU_WANT_TO_DELETE_SCHEDULE" | translate}}</p>
    <div class="d-flex align-items-center justify-content-center">
      <app-button [buttonPosition]='"center"' [buttonType]='"border"' [buttonText]='"CANCEL"' class="me-2" [mat-dialog-close]="true" ></app-button>
      <app-button [buttonPosition]='"center"' [buttonType]='"primary"' [buttonText]='"DELETE_SCHEDULE"' (buttonEvent)="deleteSchedule()"></app-button>
    </div>
  </div>
</ng-template>

<ng-template #scheduleMessage>
   <app-edit-schedule [editSchedule]='SchedulerID' (isCloseModel)='closeModel()'></app-edit-schedule>
</ng-template>
