import { Component, OnInit } from '@angular/core';
import { ICampaignStatusItem } from '@interface/campaign.modal';
import { UtilService } from '@services/util.service';
import { Router } from '@angular/router'
import {CustomFieldService} from "@services/custom-field.service";
import {ICustomFieldItems} from "@interface/custom-field.modal";

@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrls: ['./campaign.component.scss']
})
export class CampaignComponent implements OnInit {
  public breadcrumbsUrl: Array<{name: string, url: string}> = [
    {
      name: "HOME",
      url: "/dashbooard"
    },
    {
      name: "CUSTOM_FIELD",
      url: "/custom-field"
    }
  ];
  public availablePagesAmount!: number;
  campaignStatuses: Array<ICampaignStatusItem> = [];
  public campaignTable = {
    rows : [
      {
        name: "CUSTOM_FIELD_NAME",
        value: "Name"
      },
      {
        name: "CUSTOM_FIELD_DESCRIPTION",
        value: "Description"
      },
      {
        name: "MANDATORY",
        value: "Mandatory"
      },
      {
        name: "DATA_TYPE",
        value: "DataType"
      },
      {
        name: "VALIDATION_RULES",
        value: "ValidationRules"
      },
      {
        name: "ACTION",
        type: "action"
      }
    ]
  };
  customPageListPageConfig = {
    pageNumber: 1,
    pageSize: 10
  }
  public searchString = "";
  public searchOption: Array<string> = []
  public campaignTableData: Array<ICustomFieldItems> = []
  constructor(
    readonly UtilService: UtilService,
    readonly route: Router,
    readonly customService: CustomFieldService
  ) { }

  ngOnInit(): void {
    this.getCustomField()
    const campaignStatusesObj = {
      name: "All",
      value: "all",
    }
    this.campaignStatuses.splice(0, 0, campaignStatusesObj);
  }
  public print(): void {
    this.UtilService.printElement('table');
  }
  public exportCSV(): void {
    this.UtilService.exportToCSV(this.campaignStatuses, "Campaign")
  }

  AddCustomField() {
    this.route.navigate(["/custom-field", "add"]).catch()
  }
  public getCustomField(): void {
    this.customService.getAllCustomFieldList(false, this.customPageListPageConfig.pageNumber, this.customPageListPageConfig.pageSize, this.searchString).subscribe(customFields => {
      this.availablePagesAmount = customFields.totalPages;
      this.campaignTableData = customFields.items;
      customFields.items.forEach(ele => {
        this.searchOption.push(ele.Name);
      })
    })
  }

  public onPageLimitChange(pageSize: number): void {
    this.customPageListPageConfig.pageNumber = 1;
    this.customPageListPageConfig.pageSize = pageSize;
    this.getCustomField();
  }
  getSearchEvent(event: any) {
    this.customPageListPageConfig.pageNumber = 1;
    this.customPageListPageConfig.pageSize = 10;
    this.searchString = event;
    this.getCustomField();
  }
}
