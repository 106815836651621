import { Component } from '@angular/core';
import { Router } from '@angular/router';
import {LocalStorageService} from "@services/local-storage.service";
import {OAuthService} from "angular-oauth2-oidc";
@Component({
  selector: 'app-profile-widget',
  templateUrl: './profile-widget.component.html',
  styleUrls: ['./profile-widget.component.scss']
})
export class ProfileWidgetComponent {

  public isCollapsed = true;
  public showUser = true;
  public profileLink = ['/profile'];
  public user: any = {
    name: '',
    ProfileImgUrl: null,
  };

  constructor(
    private _router: Router,
    readonly localStorageService: LocalStorageService,
    readonly oauthService: OAuthService
  ) { }


  public toggle(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  public goToLogin(): void {
    this._router.navigate(['login']).catch();
  }

  public logout(): void {
    this.toggle();
    this.localStorageService.clearLocalStorage();
    this.oauthService.logOut();

  }


}
