<div class='history-pagination'>
  <div class="history-pagination-body">
    <mat-icon class='history-pagination__arrow history-pagination__arrow_left' (click)='onPreviousPageClick()'>
      chevron_left
    </mat-icon>

    <div class='history-pagination__page-value' [class.active]='i === currentPageIndex'
         (click)='onSelectPage(i)' *ngFor='let i of availablePages()'>
      <span>{{ i + 1 }}</span>
    </div>

    <mat-icon class='history-pagination__arrow history-pagination__arrow_right' (click)='onNextPageClick()'>
      chevron_right
    </mat-icon>
  </div>
</div>
