import { Component, OnInit } from '@angular/core';
import { MenuService } from '@services/menu.service';

@Component({
  selector: 'app-top-bar-navigation-list',
  templateUrl: './top-bar-navigation-list.component.html',
  styleUrls: ['./top-bar-navigation-list.component.scss']
})
export class TopBarNavigationListComponent {
  public isOpenMenu = false;
  constructor(
    readonly menuService: MenuService
  ) { }


  public openMenu(): void {
    this.isOpenMenu = !this.isOpenMenu;
  }
}
