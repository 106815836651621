<section class='contact-page'>
  <div class='container-fluid px-md-3'>
    <div class='row align-items-center mb-3'>
      <div class='col-md-6'>
        <app-heading [title]='"CONTACT_LIST_PAGE"'></app-heading>
      </div>
      <div class='col-md-6'>
        <app-breadcrumbs [navigationData]='breadcrumbsUrl'></app-breadcrumbs>
      </div>
    </div>
    <div class='row'>
      <div class='col-md-4'>
        <div class="d-flex align-items-center">
          <div class='contact-search w-100 me-3'>
            <app-search [Text]='"SEARCH"' [options]='searchOption' (searchEvent)="getSearchEvent($event)"></app-search>
          </div>
          <div class='d-flex justify-content-between justify-content-md-start'>
            <div class='page-selector'>
              <app-input [inputConfig]='{placeHolder: "", types: "pageSelector"}' [modalData]='contactListPageConfig.pageSize' (changeEvent)="onPageLimitChange($event)"></app-input>
            </div>
          </div>
        </div>
      </div>
      <div class='col-md-3 col-4 my-3 my-md-0'>
         <div class='icons d-flex ms-4'>
          <div class='export-button'>
             <mat-icon class='material-icons-outlined' (click)='print()' [matTooltip]="'PRINT' | translate ">print</mat-icon>
          </div>
          <div class='share'>
            <mat-icon class='material-icons-outlined' (click)='exportCSV()' [matTooltip]="'EXPORT' | translate ">ios_share</mat-icon>
          </div>
         </div>
      </div>
      <div class='col-md-5 col-8 my-3 my-md-0'>
        <div class='d-flex justify-content-md-end justify-content-end'>

          <app-button [buttonType]='"buttonWithIcon"' [iconName]='"person"' [buttonText]='"UPLOAD_CONTACT"' (buttonEvent)='uploadContactCSV(false)'></app-button>
        </div>
      </div>
    </div>
  </div>
  <div class='bg-gray'>
    <div class='container-fluid'>
      <div class='row'>
        <div class='col-md-12'>
          <div id='contact-table'>
            <app-table [table]='contactTable' [data]='contactTableData' (pageChangeEvent)="onPageChange($event)" (tableEvent)="getTableEvent($event)"  [availablePagesAmount]="availablePagesAmount"></app-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<ng-template #sendTemplate>
  <div class="send-message">
    <div class="row">
      <div class="col-md-12">
        <mat-label class="mb-2 d-block">{{"SENDER" | translate}}</mat-label>
        <mat-select [(ngModel)]="senderID" class="select-width ps-2">
          <mat-option *ngFor="let sender of senders" [value]="sender.ID_Senders" >
            {{sender.Name}}
          </mat-option>
        </mat-select>
      </div>
      <div class="col-md-12">
        <mat-label class="mb-2 mt-2 d-block">{{"MESSAGE_RECEIVERS" | translate}}</mat-label>
        <div class="select-width">
          <mat-chip-list #chipList>
            <mat-chip *ngFor="let receiver of receiversNumber" (removed)="remove(receiver)">
              {{receiver.name}}
              <button matChipRemove>
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip>
          </mat-chip-list>
          <input class="m-0 border-0-input w-100" type="number" [matChipInputFor]="chipList"
                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                 [matChipInputAddOnBlur]="addOnBlur"
                 (matChipInputTokenEnd)="add($event)">
        </div>
      </div>
      <div class='col-md-12'>
        <mat-label class="mt-2 pb-2 d-block">{{"SELECT_TEMPLATE" | translate}}</mat-label>
        <mat-select [(ngModel)]="scheduler_ID_Message" class="select-width ps-2" >
          <mat-option *ngFor="let sender of selectTemplateType; index as index" [value]="sender.ID_MessageTemplates" (click)="getTemplateValue(index)">
            {{sender.TemplateName}}
          </mat-option>
        </mat-select>
      </div>
      <div class='col-md-12'>
        <app-input [inputConfig]='{placeHolder: "VALUE", types: "textarea", label: "VALUE"}' [(modalData)]="template_message"></app-input>
      </div>
      <div class="col-md-12">
        <div class='dialog_button'>
          <app-button [buttonText]='"SEND_MESSAGE"' [buttonType]='"primary"' [buttonPosition]='"center"' (buttonEvent)="updateContactData()"></app-button>
        </div>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #contactTemplate>
    <mat-slide-toggle [(ngModel)]='isBulkUpload'>{{"BULK_UPLOAD" | translate}}</mat-slide-toggle>
    <div class='upload-logo' *ngIf='isBulkUpload'>
      <div class='col-md-12 mt-3'>
        <app-input [inputConfig]='{placeHolder: "SELECT_CUSTOM_FIELD", types: "select", label: "SELECT_CUSTOM_FIELD"}' [options]='customField' [isMultiple]='true'></app-input>
      </div>
      <input type='file' accept='text/csv' class='d-none' #fileUpload (change)='fileUploadEvent($event, "upload")'>
      <div class='droppable-area' appDrag (fileDropped)='fileUploadEvent($event, "drag")'>
        <div class='upload-area p-3' (click)='fileUpload.click()'>
          <div class='text-center pt-3'>
            <div class='select-btn'>
              <app-button [buttonType]='"primary"' [buttonText]='"SELECT_FROM_COMPUTER"'></app-button>
            </div>
            <div>{{ 'OR' | translate }}</div>
            <div class='drag-label'>{{ 'DRAG_IT_HERE' | translate }}</div>
          </div>
        </div>
      </div>
      <app-button [buttonText]='"SAVE_USER"' [buttonType]='"primary"' [buttonPosition]='"right"'></app-button>
    </div>
    <div class='create-contact' *ngIf='!isBulkUpload'>
      <div class='row mb-15'>
        <div class='col-md-6 mt-3'>
          <app-input [inputConfig]='{placeHolder: "FIRST_NAME", types: "input", label: "FIRST_NAME"}' [(modalData)]="updateContact.FirstName" ></app-input>
        </div>
        <div class='col-md-6 mt-3'>
          <app-input [inputConfig]='{placeHolder: "LAST_NAME", types: "input", label: "LAST_NAME"}' [(modalData)]="updateContact.LastName"></app-input>
        </div>
        <div class='col-md-4 mt-3'>
          <app-input [inputConfig]='{placeHolder: "PHONE_PREFIX", types: "select", label: "PHONE_PREFIX"}' [options]='phonePrefix' [(modalData)]="updateContact.MobilephonePrefix"></app-input>
        </div>
        <div class='col-md-8 mt-3'>
          <app-input [inputConfig]='{placeHolder: "CONTACT_NUMBER", types: "input", label: "CONTACT_NUMBER", inputType: "number"}' [(modalData)]="updateContact.MobilephoneNumber"></app-input>
        </div>
        <div class='col-md-4 mt-3'>
          <mat-label class="label">{{"SELECT_BIRTHDATE" | translate}}</mat-label>
          <div  class='recurrenceType0 d-flex'>
            <input class='input w-100' matInput [matDatepicker]="$any(picker)" [placeholder]='"SELECT_BIRTHDATE" | translate' (click)='picker.open()' [max]='lastDate' (dateChange)='getDateChange($event)' [(ngModel)]="updateContact.DOB">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </div>
        </div>
        <div class='col-md-8 mt-3'>
          <app-input [inputConfig]='{placeHolder: "SELECT_AGE", types: "select", label: "SELECT_AGE"}' [options]='selectAge' [(modalData)]='selectedAge'></app-input>
        </div>
        <div class='col-md-4 mt-3'>
          <app-input [inputConfig]='{placeHolder: "COUNTRY_ISO", types: "select", label: "COUNTRY_ISO"}' [options]='userLanguage' [(modalData)]="updateContact.CountryISO"></app-input>
        </div>
        <div class='col-md-4 mt-3'>
          <app-input [inputConfig]='{placeHolder: "LANGUAGE_ISO", types: "select", label: "LANGUAGE_ISO"}' [options]='languageISO' [(modalData)]="updateContact.LanguageISO"></app-input>
        </div>
        <div class='col-md-4 mt-3'>
          <app-input [inputConfig]='{placeHolder: "GENDER", types: "select", label: "GENDER"}' [options]='genderOption' [(modalData)]="updateContact.ID_Gender"></app-input>
        </div>
        <div class='col-md-12 mt-3'>
          <app-input [inputConfig]='{placeHolder: "SELECT_CUSTOM_FIELD", types: "select", label: "SELECT_CUSTOM_FIELD"}' [options]='customField' [isMultiple]='true' (selectIndex)="getSelectedCustomField($event)" [selectedOption]="selectedCustomField"></app-input>
        </div>
        <ng-container *ngFor="let selectedField of selectedCustomField; index as i">
          <ng-container *ngIf="selectedField.isSelected">
            <div class="col-md-12 mt-3">
              <mat-card>
                <div class="row">
                  <div class='col-md-6 ' >
                    <mat-label class="label">{{selectedField.Name}} </mat-label>
                  </div>
                  <div class='col-md-6' >
                    <app-input [inputConfig]='{placeHolder: "", types: "input"}' [(modalData)]="selectedCustomField[i].Value"></app-input>
                  </div>
                </div>
              </mat-card>
            </div>
          </ng-container>
        </ng-container>
      </div>
      <div class='dialog_button'>
        <app-button [buttonText]='"SAVE_USER"' [buttonType]='"primary"' [buttonPosition]='"center"' (buttonEvent)="updateContactData()"></app-button>
      </div>
    </div>
</ng-template>
