import { Component, OnDestroy } from '@angular/core'
import { Subscription } from 'rxjs';
import { LanguageService } from '@services/language.service';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnDestroy {

  public isCollapsed = true;
  public langSubscriber: Subscription;
  public flag: string = '';
  public selectLanguage: string = '';

  constructor(private langService: LanguageService) {
    this.langSubscriber = this.langService.currentLanguage.subscribe((lang: string) => {
      if (lang === 'en') {
        this.flag = 'assets/images/en.png';
        this.selectLanguage = 'ENG';
      } else {
        this.flag = 'assets/images/pt.jpg';
        this.selectLanguage = 'Port';
      }
    });
  }

  public ngOnDestroy(): void {
    this.langSubscriber.unsubscribe();
  }

  public setLanguage(lang: string): void {
    this.togglePanel();
    this.langService.setLanguage(lang);
  }

  public togglePanel(): void {
    this.isCollapsed = !this.isCollapsed;
  }
}
