<section class='tabs'>
  <mat-tab-group (selectedTabChange)='updateTabsEvent($event)'>
    <ng-container *ngFor='let tab of tabsData'>
      <mat-tab>
        <ng-template mat-tab-label>
          {{tab.label | translate}}
          <span class='tabsCount d-none d-md-block'>{{tab.count}}</span>
        </ng-template>
      </mat-tab>
    </ng-container>
  </mat-tab-group>
</section>
