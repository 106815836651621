import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core'
import {MessageService} from "@services/message.service";
import {ScheduleService} from "@services/schedule.service";
import {ScheduleValidation} from "./schedule.validation";
import {NotifierServices} from "@services/notification.service";
import {UtilService} from "@services/util.service";
import {Router} from "@angular/router";
@Component({
  selector: 'app-edit-schedule',
  templateUrl: './edit-schedule.component.html',
  styleUrls: ['./edit-schedule.component.scss']
})
export class EditScheduleComponent implements OnInit{
  @Input() editSchedule!: number | string;
  @Output() public isCloseModel = new EventEmitter<boolean>();
  FilterClauseConditions = `{"tableName": " ",
      "clauseStatment": [
        {
          "columnName": " ",
          "comparisonOperator": " ",
          "filtterValue": " ",
          "logicalOperator": " "
        }]
    }`;
  isEdit = false;
  filterCount = 0;
  monthlyDays: Array<number> = [];
  daysSelected: any[] = [];
  event: any;
  isSelected = (event: any) => {
    const date =
      event.getFullYear() +
      "-" +
      ("00" + (event.getMonth() + 1)).slice(-2) +
      "-" +
      ("00" + event.getDate()).slice(-2);
    return this.daysSelected.find(x => x == date) ? "selected" : "null";
  };
  public recurrenceType = [
    {
      name: "Daily",
      value: 1
    },
    {
      name: "Weekly",
      value: 2
    },
  ];
  public dayOfWeeks = [
    {
      name: "MON",
      value: "1"
    },
    {
      name: "TUE",
      value: "2"
    },
    {
      name: "WED",
      value: "3"
    },
    {
      name: "THU",
      value: "4"
    },
    {
      name: "FRI",
      value: "5"
    },
    {
      name: "SAT",
      value: "6"
    },
    {
      name: "SUN",
      value: "7"
    }
  ]
  public selectTemplateType: Array<{name: string, value: number}> = [];
  schedulerData: any = [{
    SchedullerTitle: "",
    ID_RecurrenceType: 0,
    ID_RecurrenceTemplate: 0,
    StartDate: "",
    EndDate: "",
    SeparationCount: 0,
    SendTime: "",
    DaysOfWeek: 0,
    DayOfMonth: 0,
    WeekOfMonth: 0,
    MonthOfYear: 0,
    CreatedBy: "",
    UpdatedBy: "",
    CreatedDate: "",
    UpdatedDate: "",
    FilterClauseConditions: `{"tableName": " ",
      "clauseStatment": [
        {
          "columnName": " ",
          "comparisonOperator": " ",
          "filtterValue": " ",
          "logicalOperator": " "
        }]
    }`
  }];
  public contactListPageConfig = {
    pageNumber: 1,
    pageSize: 10
  }
  constructor(
    readonly messageService: MessageService,
    readonly scheduleService: ScheduleService,
    readonly notificationService: NotifierServices,
    readonly utilityService: UtilService,
    readonly router: Router
  ) { }
  ngOnInit(): void {
    for (let i = 1; i < 32; i++) {
      this.monthlyDays.push(i)
    }
    this.getAllMessageTemplate();
    if(this.editSchedule !== "add") {
      this.isEdit = true;
      this.getScheduleDetails(this.editSchedule);
    } else {
      this.isEdit = false;
      this.schedulerData = [{
        SchedullerTitle: "",
        ID_RecurrenceType: 0,
        ID_RecurrenceTemplate: 0,
        StartDate: "",
        EndDate: "",
        SeparationCount: 0,
        SendTime: "",
        DaysOfWeek: 0,
        DayOfMonth: 0,
        WeekOfMonth: 0,
        MonthOfYear: 0,
        CreatedBy: "",
        UpdatedBy: "",
        CreatedDate: "",
        UpdatedDate: "",
        FilterClauseConditions: `{"tableName": " ",
      "clauseStatment": [
        {
          "columnName": " ",
          "comparisonOperator": " ",
          "filtterValue": " ",
          "logicalOperator": " "
        }]
    }`
      }];
    }
  }

  selectEvent(event: any) {
    if (event === 1) {
      this.schedulerData[0].SeparationCount = 1
    } else {
      this.schedulerData[0].SeparationCount = 0
    }
    this.schedulerData[0].ID_RecurrenceType = event
  }
  select(event: any, calendar: any) {
    const date =
      event.getFullYear() +
      "-" +
      ("00" + (event.getMonth() + 1)).slice(-2) +
      "-" +
      ("00" + event.getDate()).slice(-2);
    const index = this.daysSelected.findIndex(x => x == date);
    if (index < 0) this.daysSelected.push(date);
    else this.daysSelected.splice(index, 1);

    calendar.updateTodaysDate();
  }
  public getAllMessageTemplate(): void {
    this.messageService.getMessageTemplates(this.contactListPageConfig.pageNumber, this.contactListPageConfig.pageSize, "").subscribe(res => {
      res.items.forEach(template => {
        this.selectTemplateType.push({
          name: template.TemplateName,
          value: template.ID_MessageTemplates
        })
      })
    })
  }

  createSchedule() {
    const isValid = ScheduleValidation.checkScheduleValidation(this.schedulerData[0]);
    if(isValid === true) {
      this.schedulerData[0].UpdatedDate = new Date();
      this.schedulerData[0].UpdatedBy = JSON.parse(localStorage.getItem("user") || "").name;
      if(this.isEdit) {
        this.schedulerData[0].FilterClauseConditions = JSON.parse(this.schedulerData[0].FilterClauseConditions);
        this.scheduleService.patchScheduleDetails(this.schedulerData[0], this.schedulerData[0].ID_Scheduller).subscribe((res: any) => {
          this.notificationService.showNotification("success", res.message);
        },  (err: any) => {
          this.notificationService.showNotification("error", err.error.message);
        },  () => {
          this.isCloseModel.emit(true);
        })
      } else {
        this.schedulerData[0].CreatedDate = new Date()
        this.schedulerData[0].CreatedBy = JSON.parse(localStorage.getItem("user") || "").name;
        this.schedulerData[0].FilterClauseConditions = JSON.parse( this.schedulerData[0].FilterClauseConditions);
        this.scheduleService.postScheduleDetails(this.schedulerData[0]).subscribe(res => {
          this.notificationService.showNotification("success", res.message);
          this.isCloseModel.emit(true);
        }, err => {
          this.notificationService.showNotification("error", err.error.message);
          this.isCloseModel.emit(true);
        },  () => {
          this.isCloseModel.emit(true);
        })
      }
    } else {
      this.utilityService.getTranslate(isValid).then(result => {
        this.notificationService.showNotification("error", result);
      })
    }
  }

  public getScheduleDetails(id: any) {
    this.scheduleService.getScheduleDetails( id).subscribe(result => {
      this.schedulerData = result;
      this.FilterClauseConditions = result[0].FilterClauseConditions;
    })
  }

  getTextAreaEvent(event: any) {
    this.schedulerData[0].FilterClauseConditions = event.target.value;
  }

  emitEvent() {
    this.isCloseModel.emit(true);
  }
}
