import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-heading',
  templateUrl: './heading.component.html',
  styleUrls: ['./heading.component.scss']
})
export class HeadingComponent  {
  @Input() public title: string = '';
  @Input() public fontSize: string = '';
  @Input() public subTitle: string = '';
}
