import { Component } from '@angular/core';
import { PageLoaderService } from '@services/page-loader.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html'
})
export class LoaderComponent {

  public loading: boolean = true;

  constructor(
    private loaderService: PageLoaderService,
    private ngxService: NgxUiLoaderService,
  ) {
    this.loaderService.isLoading.subscribe((v: boolean) => {
      this.loading = v;
      if (v) {
        this.ngxService.start();
      } else {
        this.ngxService.start();
      }
    });
  }

}
