<div class='d-flex align-items-center d-lg-none menu-toggle' (click)='openMenu()'>
  <mat-icon class="material-icons-outlined cursor-pointer">menu</mat-icon>
</div>
<div class='navigation-link' [ngClass]='{"isOpen": isOpenMenu}'>
  <ng-container *ngFor='let items of menuService.EnableChannels'>
    <ng-container *ngIf='!items.isChild'>
      <a class='link' [routerLink]='items.routerLink' [routerLinkActive]='"activeMenu"' [routerLinkActiveOptions]="{ exact: true }">
        <span>{{items.name | translate}}</span>
      </a>
    </ng-container>
    <ng-container *ngIf='items.isChild'>
      <ng-container *ngFor='let child of items.childLink'>
        <app-top-bar-select [label]='items.name' [link]='items.routerLink'>
          <a class='link' [routerLink]='child.routerLink' [routerLinkActive]='"activeMenu"' [routerLinkActiveOptions]="{ exact: true }">
            <span>{{child.name | translate}}</span>
          </a>
        </app-top-bar-select>
      </ng-container>

    </ng-container>
  </ng-container>
</div>
