import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { ContactService } from '@services/contact.service';
import { UtilService } from '@services/util.service';
import { ModalComponent } from  '../../component/modal/modal.component'
import { MatDialog } from '@angular/material/dialog'
import { CustomFieldService } from '@services/custom-field.service'
import {NotifierServices} from "@services/notification.service";
import { ContactValidation } from "./contact.validation";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {MatChipInputEvent} from "@angular/material/chips";
import {MessageService} from "@services/message.service";
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  @ViewChild('sendTemplate', {static: false}) sendTemplate!: TemplateRef<any>;
  @ViewChild('contactTemplate', {static: false}) contactTemplate!: TemplateRef<any>;

  private isEdit: boolean = false;
  public availablePagesAmount!: number;
  public customFieldIndexArray: Array<number> = [];
  public searchOption: Array<string> = []
  public lastDate = new Date();
  public phonePrefix: any = [];
  public userLanguage: any = [];
  public languageISO: any = [];
  public senders: any = [];
  public senderID: number = JSON.parse(localStorage.getItem('senderID') || '');
  public dialogRef: any;
  public isBulkUpload = false;
  public selectedCustomField: any = [];
  public genderOption: any = [];
  public customField: Array<{name: string, value: number}> = [];
  public selectAge: any = [];
  public selectedAge: number = 0;
  public receiversNumber: any = [];
  public scheduler_ID_Message: any = "";
  public selectTemplateType: any = [];
  public addOnBlur = true;
  public template_message: string = "";
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  public breadcrumbsUrl: Array<{name: string, url: string}> = [
    {
      name: "HOME",
      url: "/dashbooard"
    },
    {
      name: "CONTACT",
      url: "/contact"
    }
  ];
  public contactTable = {
    rows : [
      {
        name: "",
        value: "shortName",
        type: "shortName",
      },
      {
        name: "NAME",
        value: "name"
      },
      {
        name: "TELEPHONE",
        value: "telephone"
      },
      {
        name: "AGE_RANGE",
        value: "AgeRange"
      },
      {
        name: "GENDER",
          value: "Gender"
      },
      {
        name: "UPDATED_BY",
        value: "UpdatedBy"
      },
      {
        name: "UPDATED_DATE",
        value: "UpdatedDate",
        type: "date"
      },
      {
        name: "ACTION",
        type: "action",
        isSendButton: true
      }
    ]
  };
  public updateContact: any = {}
  public contactTableData: Array<{
    name: string,
    telephone: string,
    AgeRange: string,
    Gender: string,
    UpdatedBy: string,
    UpdatedDate: string,
    id: number
  }> = [];
  contactListPageConfig = {
    pageNumber: 1,
    pageSize: 10
  }
  searchString = "";
  constructor(
    readonly contactService: ContactService,
    readonly UtilService: UtilService,
    readonly dialog: MatDialog,
    readonly customService: CustomFieldService,
    readonly notificationService: NotifierServices,
    readonly messageService: MessageService,

  ) { }

  ngOnInit(): void {
    this.initBlankObj();
    const date = new Date().getFullYear() - 20;
    this.lastDate = new Date(this.lastDate.setFullYear(date))
    this.getContactList();
    this.getCustomField();
    this.selectAge = this.UtilService.returnAge;
    this.genderOption = this.UtilService.genderOption;
    this.phonePrefix = this.UtilService.phonePrefix;
    this.userLanguage = this.UtilService.userLanguage;
    this.languageISO = this.UtilService.languageISO;
  }
  private getContactList() : void {
    this.contactService.getContactList(this.contactListPageConfig.pageNumber, this.contactListPageConfig.pageSize, this.searchString).subscribe(contactList => {
      this.contactTableData = [];
      this.availablePagesAmount = contactList.totalPages;
      contactList.items.forEach(contacts => {
       const contactObj = {
         shortName: `${contacts.FirstName.substr(0, 1)}${contacts.LastName.substr(0, 1).toUpperCase()}`,
         name: `${contacts.FirstName} ${contacts.LastName}`,
         telephone: `+${contacts.MobilephonePrefix } ${contacts.MobilephoneNumber }`,
         AgeRange: this.contactService.returnAge(contacts.AgeRange),
         Gender: this.contactService.returnGender(contacts.ID_Gender),
         UpdatedBy: contacts.UpdatedBy,
         UpdatedDate: contacts.UpdatedDate,
         id: contacts.ID_Contacts
       };
       this.searchOption.push(contacts.FirstName)
       this.contactTableData.push(contactObj);
      })
    })
  }

  public onPageChange(pageNumber: number): void {
    this.contactListPageConfig.pageNumber = pageNumber;
    this.getContactList();
  }

  public onPageLimitChange(pageSize: number): void {
    this.contactListPageConfig.pageNumber = 1;
    this.contactListPageConfig.pageSize = pageSize;
    this.getContactList();
  }

  public print(): void {
    this.UtilService.printElement('table');
  }

  public exportCSV(): void {
    this.UtilService.exportToCSV(this.contactTableData, "Campaign")
  }

  uploadContactCSV( isEdit: boolean) {
    this.initBlankObj();
    this.selectedCustomField.forEach((ele: any) => {
      ele.isSelected = false
    })
    this.isEdit = isEdit;
    this.selectedAge = 0;
    const contactTemp = this.contactTemplate;
    this.dialogRef = this.dialog.open(ModalComponent, {
      data: { title: "UPLOAD_CONTACT",template: contactTemp , isShowHeader: true },
      height: "auto",
      width: "40%",
    });
  }
  async fileUploadEvent(event: any, uploadType: string): Promise<any> {
    let file;
    if (uploadType === "drag"){
      file = event[0]
    } else {
      file = event.target.files[0];
    }
    let reader = new FileReader();
    reader.onload = function () {
      // if(typeof reader.result === "string") {
      //   // console.log(reader.result.split("\n")[0].split(";"));
      //   reader.result.split("\n").forEach(ele => {
      //      // const splitText = ele.split(";");
      //      // splitText.forEach(element => {
      //      //   console.log(element)
      //      // })
      //   })
      // }
    };

    reader.readAsBinaryString(file);
  }

  getDateChange(event: any) {
    const selectedYear = new Date(event.value).getFullYear();
    const currentYear = new Date().getFullYear() - selectedYear;
    if(currentYear <= 30) {
      this.selectedAge = 1;
    } else if(currentYear <= 39) {
      this.selectedAge = 2;
    } else if(currentYear <= 50) {
      this.selectedAge = 3;
    } else {
      this.selectedAge = 4;
    }
  }

  public getCustomField(): void {
    this.customService.getAllCustomFieldList(true).subscribe(customFields => {
      this.selectedCustomField = customFields.items;
      customFields.items.forEach((ele, index) => {
        this.selectedCustomField[index].isSelected = false;
        const customField = {
          name: ele.Name,
          value: ele.ID_CustomFields
        };
        this.customField.push(customField)
      });
    })
  }

  public getTableEvent(event: any): void {
    if(event.type === "edit") {
      this.uploadContactCSV(true);
      this.contactService.getContactData(event.data.id).subscribe((contactDetails: any) => {
        this.updateContact = contactDetails[0];
        this.selectedCustomField.forEach((ele: any) => {
          ele.value = ele.ID_CustomFields;
          ele.isSelected = JSON.parse(contactDetails[0].CustomFields).find((element: any) => element.ID_CustomFields === ele.ID_CustomFields) !== undefined;
        });
        this.selectedAge = this.updateContact.AgeRange;
      });
    } else if (event.type === "send") {
      const tempTemplate = this.sendTemplate;
      this.dialogRef = this.dialog.open(ModalComponent, {
        data: { title: "MESSAGES", template:tempTemplate, isShowHeader: true },
        height: "auto",
        width: "530px",
      });
      this.getAllMessageTemplate();
      this.UtilService.getSender().subscribe(
        (res: any) => {
          this.senders = res.items;
          if(res.items.length) {
            localStorage.setItem('senderID', res.items[0].ID_Senders);
          }
        }
      );
    }
  }



  public getSelectedCustomField(event: any): void {
    if(event.event) {
      this.customFieldIndexArray.push(event.index);
      this.selectedCustomField[event.index].isSelected = true;
    } else {
      this.selectedCustomField[event.index].isSelected = false;
      this.customFieldIndexArray.forEach((number: number, index: number) => {
        if (number === event.index) {
          this.customFieldIndexArray.splice(index, 1)
        }
      })
    }
  }

  public updateContactData(): void {
    const isValid = ContactValidation.checkContactValidation(this.updateContact);
    if(isValid === "successfully") {
      if(this.customFieldIndexArray.length) {
        this.updateContact.CustomFields = [];
        this.selectedCustomField.forEach((ele: any) => {
          if (ele.isSelected) {
            const customObj = {
              ID_CustomFields: ele.ID_CustomFields,
              Value: ele.Value,
              UpdatedBy: "Derson Andrade",
              UpdatedDate: new Date(),
              CreatedBy: "Derson Andrade",
              CreatedDate: new Date(),
            }
            this.updateContact.CustomFields.push(customObj)
          }
        })
      }
      this.updateContact.AgeRange = this.selectedAge;
      if(this.isEdit) {
        this.contactService.updateContactData(this.updateContact.ID_Contacts, this.updateContact).subscribe((result: any) => {
          this.notificationService.showNotification("success",result.message);
          this.dialogRef.close();
          this.getContactList();
        }, error => {
          this.notificationService.showNotification("error",error.error.message? error.error.message : "Something went wrong");
          this.dialogRef.close();
        }, );
      } else {
          this.updateContact.CreatedBy = "Derson Andrade";
          this.updateContact.CreatedDate = new Date();
          this.updateContact.UpdatedBy = "Derson Andrade";
          this.updateContact.UpdatedDate = new Date();
          this.contactService.createContactData(this.updateContact).subscribe(result => {
            if (result.status === "success") {
              this.notificationService.showNotification("success", "Contact Create Successfully.");
              this.getContactList();
              this.dialogRef.close();
            }
          },  error => {
            this.notificationService.showNotification("error", error.error.message? error.error.message : "Something went wrong");
            this.dialogRef.close();
          });
      }
    } else {
      this.notificationService.showNotification("error", isValid)
    }
  }
  public initBlankObj() {
  this.updateContact = {
      FirstName: "",
      LastName: "",
      MobilephonePrefix: "",
      MobilephoneNumber: "",
      DOB: "",
      AgeRange: this.selectedAge,
      LanguageISO: "",
      CountryISO: "",
      ID_Gender: 0,
      Enabled: true,
      CustomFields: [],
      UpdatedBy: "",
      UpdatedDate: ""
    }
  }
  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.receiversNumber.push({name: value});
    }
    event.chipInput!.clear();
  }

  remove(fruit: any): void {
    const index = this.receiversNumber.indexOf(fruit);

    if (index >= 0) {
      this.receiversNumber.splice(index, 1);
    }
  }
  public getAllMessageTemplate(): void {
    this.messageService.getMessageTemplates(this.contactListPageConfig.pageNumber, this.contactListPageConfig.pageSize, "").subscribe(res => {
      this.selectTemplateType = res.items;
    })
  }

  getTemplateValue(event: number) {
    this.template_message = this.selectTemplateType[event].Message;
  }

  getSearchEvent(event: any) {
    this.contactListPageConfig.pageNumber = 1;
    this.contactListPageConfig.pageSize = 10;
    this.searchString = event;
    this.getContactList();
  }
}
