import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { MaterialModule } from "./material.module";
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { SharedModule } from "./shared.module";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ContactComponent } from './pages/contact/contact.component';
import { CampaignComponent } from './pages/custom-field/campaign.component';
import { LogsComponent } from './pages/logs/logs.component';
import { MessageComponent } from './pages/message/message.component';
import { MessageTemplateListComponent } from './pages/message/message-template-list/message-template-list.component';
import { EditMessageTemplateComponent } from './pages/message/edit-message-template/edit-message-template.component';
import { UserComponent } from './pages/user/user.component';
import { AddUserComponent } from './pages/user/add-user/add-user.component';
import { ScheduleComponent } from './pages/schedule/schedule.component';
import { EditScheduleComponent } from './pages/schedule/edit-schedule/edit-schedule.component';
import { AddCustomFieldComponent } from './pages/custom-field/add-custom-field/add-custom-field.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { NotifierModule, NotifierOptions } from "angular-notifier";
import { MatNativeDateModule } from '@angular/material/core';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import { FormsModule } from '@angular/forms';
import { TokenInterceptor } from "./interceptor/token.interceptor";
import {OAuthModule} from "angular-oauth2-oidc";
import { LogInComponent } from './log-in-page/log-in.component';
import {DashboardComponent} from "./pages/dashboard/dashboard.component";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
const notifierDefaultOptions: NotifierOptions = {
  position: {
    horizontal: {
      position: 'right',
      distance: 12,
    },
    vertical: {
      position: 'bottom',
      distance: 12,
      gap: 10,
    },
  },
  theme: 'material',
  behaviour: {
    autoHide: 500000,
    onClick: false,
    onMouseover: 'pauseAutoHide',
    showDismissButton: true,
    stacking: 4,
  },
  animations: {
    enabled: true,
    show: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
    },
    hide: {
      preset: 'fade',
      speed: 300,
      easing: 'ease',
      offset: 50,
    },
    shift: {
      speed: 300,
      easing: 'ease',
    },
    overlap: 150,
  },
};
@NgModule({
  declarations: [
    AppComponent,
    ContactComponent,
    CampaignComponent,
    LogsComponent,
    MessageComponent,
    MessageTemplateListComponent,
    EditMessageTemplateComponent,
    UserComponent,
    AddUserComponent,
    ScheduleComponent,
    EditScheduleComponent,
    AddCustomFieldComponent,
    LogInComponent,
    DashboardComponent
  ],
    imports: [
        HttpClientModule,
        MatNativeDateModule,
        NgxMatTimepickerModule,
        OAuthModule.forRoot({
            resourceServer: {
                sendAccessToken: false,
            },
        }),
        NotifierModule.withConfig(notifierDefaultOptions),
        BrowserModule, AppRoutingModule, BrowserAnimationsModule, MaterialModule, SharedModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        }), ColorPickerModule, FormsModule,OwlDateTimeModule,
      OwlNativeDateTimeModule,
    ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
    exports: [

    ]
})
export class AppModule {}
