import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment  } from '../../environments/environment'
import {IContactCustomFields, IContactInterface, response} from '@interface/contact.modal';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(
    private _httpClient: HttpClient,
  ) { }

  public getContactList(pageNumber: number, pageSize: number, search: string): Observable<IContactInterface> {
    let url = "";
    if(search) {
      url = `${environment.apiUrl}/contacts?q=FirstName='${search}'&pageNumber=${pageNumber}&pageSize=${pageSize}`
    } else {
      url = `${environment.apiUrl}/contacts?pageNumber=${pageNumber}&pageSize=${pageSize}`
    }
    return this._httpClient.get<IContactInterface>(url);
  }

  public getContactData(contactId: string): Observable<Array<IContactCustomFields>> {
    const url = `${environment.apiUrl}/contacts/${contactId}`
    return this._httpClient.get<Array<IContactCustomFields>>(url);
  }

  public createContactData(body: Array<IContactInterface>): Observable<response> {
    const url = `${environment.apiUrl}/contacts`
    return this._httpClient.post<response>(url, body);
  }

  public updateContactData(contactId: string, body: IContactInterface): Observable<Array<IContactInterface>> {
    const url = `${environment.apiUrl}/contacts/${contactId}`
    return this._httpClient.patch<Array<IContactInterface>>(url, body);
  }
  public returnAge(ageId: number) {
    if (ageId === 1) {
      return "20 to 30"
    } else if (ageId === 2){
      return "31 to 39"
    } else if (ageId === 3) {
      return "40 to 50"
    } else {
      return "over 50"
    }
  }
  public returnGender(genderId: number) {
    if (genderId === 1) {
      return "Male"
    } else if (genderId === 2){
      return "Female"
    } else {
      return "Other"
    }
  }
}
