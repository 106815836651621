import { Component } from '@angular/core';
import * as powerbi from 'powerbi-client';
import * as pbimodels from 'powerbi-models';
import {PowerBIService} from "@services/power-bi.service";
import {LanguageService} from "@services/language.service";

require('powerbi-models');
require('powerbi-client');
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent  {

  constructor(
    readonly powerBIService: PowerBIService,
    readonly languageService: LanguageService
  ) {
    this.GetAccessToken();
  }

  public GetAccessToken() {
    const clientId = '9ce94f50-7c3e-482e-a3bc-3c89d1b02787';
    const clientSecret = 'Hj0RRooVI5lvQuNQsc5Hgpo0XXTvSZn9rjUCna2Q+UNowTUBDuyWq7NPynDSpoIH';
    const tenantId = 'c9292196-4716-40fe-a556-f76fb14adc8a';
    const scope = ['https://analysis.windows.net/powerbi/api/.default'];
    const groupId = '904f0846-e36a-482c-8831-b888d59e9082';
    const reportId = 'fe576556-b8bd-49fd-ba6a-73741a703e41';
    this.powerBIService.GetAzureAccessToken(tenantId, clientId, clientSecret, scope)
      .subscribe((oauthToken) => {
        if (oauthToken.access_token !== undefined) {
          this.powerBIService.GetPowerBIEmbeddedReport(oauthToken.access_token, 'view', groupId, reportId)
            .subscribe(report => {
              // @ts-ignore
              const userData: any = "JSON.parse(sessionStorage.getItem('id_token_claims_obj'))";
              this.powerBIService.GetUsersRoles(userData.userKey, this.languageService.language).subscribe((user) => {
                if (user !== undefined) {
                  // Checks if user is super admin, then it gives access to full data
                  if (user.roleId == 1) {
                    this.loadReport(report.token, groupId, reportId, 'HealthEst', 'HealthEstNumber', [''], 'All');
                  }
                  else {
                    // When user is not super admint then passess the Health Establishment Numbers that the user has access as a filter
                    const userEstablishments = user.establishments;
                    const filterVal: string[] = [""];
                    userEstablishments.forEach((element: any) => {
                      filterVal.push(element.healthEstNumber);
                    });
                    this.loadReport(report.token, groupId, reportId, 'HealthEst', 'HealthEstNumber', filterVal, 'In');
                  }
                }
                else {
                  this.loadReport(report.token, groupId, reportId, 'HealthEst', 'HealthEstNumber', [''], 'In');
                }
              });
            });
        }
      });
  }

  public loadReport(embeddedToken: string, groupId: string, reportId: string, filterTable: string, filterColumn: string, filterVal: string[], filterOperator: string): void {
    // get DOM object div for report container
    const reportContainer: any = document.getElementById('pbi-embed-container');

    // apply the current user HHC access by passing the ID_HealthEst as a filter
    const healthEst = {
      $schema: 'http://powerbi.com/product/schema#basic',
      target: {
        table: filterTable,
        column: filterColumn
      },
      operator: filterOperator,
      values: filterVal,
      filterType: pbimodels.FilterType.Basic,
      requireSingleSelection: false
    };

    const config: powerbi.IEmbedConfiguration = {
      type: 'report',
      id: reportId,
      embedUrl: `https://app.powerbi.com/reportEmbed?reportId=${reportId}&groupId=${groupId}`,
      accessToken: embeddedToken,
      permissions: pbimodels.Permissions.All,
      tokenType: pbimodels.TokenType.Embed,
      viewMode: pbimodels.ViewMode.View,
      filters: [healthEst],
      settings: {
        panes: {
          filters: { expanded: false, visible: false },
          pageNavigation: { visible: false}// true, position: pbimodels.PageNavigationPosition.Left }
        },
        localeSettings: {
          language: this.languageService.language,
          formatLocale: this.languageService.language // pt-PT
        },
        persistentFiltersEnabled: false,
        background: pbimodels.BackgroundType.Transparent,
        layoutType: pbimodels.LayoutType.Custom,
        customLayout: {
          displayOption: pbimodels.DisplayOption.FitToPage// FitToPage FitToWidth
        }
      }
    };

    // Embed the report and display it within the div container.
    const report = window?.powerbi?.embed(reportContainer, config);
    reportContainer.powerBiEmbed.iframe.style.height = '99%';
    reportContainer.powerBiEmbed.iframe.style.border = 'none';
  }

}
