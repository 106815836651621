<section class='general-information'>
  <div class='container-fluid'>
    <div class='row justify-content-center'>
      <div class='col-md-5'>
        <div class='card'>
          <div class='row' >
            <div class='col-md-12'>
              <mat-card>
                <mat-card-title>
                  <div class="d-flex align-items-center justify-content-between">
                    <app-heading [title]='"ADD_CUSTOM_FIELD"'></app-heading>
                    <mat-icon class='material-icons-outlined c-p' [routerLink]="['/custom-field']">cancel</mat-icon>
                  </div>
                </mat-card-title>
                <mat-card-content>
                  <div class='row'>
                    <div class='col-md-12'>
                      <app-input [inputConfig]='{placeHolder: "CONTACT_ID", types: "input", label: "CONTACT_ID"}'></app-input>
                    </div>
                    <div class='col-md-12 mt-3'>
                      <app-input [inputConfig]='{placeHolder: "CUSTOM_FIELD_ID", types: "input", label: "CUSTOM_FIELD_ID"}'></app-input>
                    </div>
                    <div class='col-md-12 mt-3'>
                      <app-input [inputConfig]='{placeHolder: "VALUE", types: "input", label: "VALUE"}'></app-input>
                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
          </div>
          <div class='row justify-content-end'>
            <div class='col-md-12'>
              <div class='d-flex justify-content-end'>
                <app-button class='d-block mt-3' [buttonType]='"primary"' [buttonPosition]='"right"' [buttonText]='"SAVE_TEMPLATE"'></app-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
