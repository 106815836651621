<section class='general-information'>
  <div class='container-fluid'>
    <div class='row justify-content-center'>
      <div class='col-md-6'>
        <div class='card'>
          <div class='row' >
            <div class='col-md-12'>
              <mat-card class="pt-0">
                <mat-card-title>
                  <div class="d-flex align-items-center justify-content-between mb-3">
                    <app-heading [title]='"GENERAL_INFORMATION"'></app-heading>
                    <mat-icon class='material-icons-outlined c-p' [routerLink]="['/template']">cancel</mat-icon>
                  </div>
                </mat-card-title>
                <mat-card-content>
                  <div class='row'>
                    <div class='col-md-6'>
                      <app-input [inputConfig]='{placeHolder: "TEMPLATE_NAME", types: "input", label: "TEMPLATE_NAME"}' [modalData]='templateObj.TemplateName'></app-input>
                    </div>
                    <div class='col-md-6'>
                      <app-input [inputConfig]='{placeHolder: "TEMPLATE_CATEGORY", types: "select", label: "TEMPLATE_CATEGORY"}' [options]='templateOption' [modalData]='templateObj.ID_Category'></app-input>
                    </div>
                  </div>
                </mat-card-content>

                <mat-card-title>
                  <app-heading [title]='"MESSAGE_TEMPLATE"'></app-heading>
                  <p class='message-temp'>{{"MESSAGE_TEMPLATE_DIS" | translate}}</p>
                </mat-card-title>
                <mat-card-content>
                  <div class='row'>
                    <div class='col-md-12'>
                      <app-input [inputConfig]='{placeHolder: "MESSAGE_LANGUAGE", types: "select", label: "MESSAGE_LANGUAGE"}' [options]='templateLanguage' [modalData]='templateObj.LanguageISO'></app-input>
                      <app-input [inputConfig]='{placeHolder: "MESSAGE_BODY", types: "textarea", label: "MESSAGE_BODY"}' [modalData]='templateObj.Message'></app-input>

                    </div>
                  </div>
                </mat-card-content>
              </mat-card>
            </div>
          </div>
          <div class='row justify-content-end'>
            <div class='col-md-12'>
              <div class='d-flex justify-content-end'>
                <app-button class='d-block mt-3 me-3 ' [buttonType]='"border"' [buttonPosition]='"right"' [buttonText]='"SAVE_AND_CREATE_SCHEDULE"'></app-button>
                <app-button class='d-block mt-3' [buttonType]='"primary"' [buttonPosition]='"right"' [buttonText]='"SAVE_TEMPLATE"'></app-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
