import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'prettyPrint'
})
export class PrettyPrintPipe implements PipeTransform {
transform(val: any){
  if (typeof val === 'string') {
      return JSON.stringify(JSON.parse(val as string), null, 2);
    } else {
      return val;
    }
  }
}
