import { Component, OnInit } from '@angular/core';
import { MessageService } from '@services/message.service'
import { Router } from '@angular/router'

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent {
  templateObj: any = {};
  constructor(
    public messageService : MessageService,
    readonly route: Router
  ) {
    this.messageService.isEditMessage = route.url !== "/template";
  }


  getButtonEvent(event: any) {
    this.messageService.isEditMessage = true;
  }
}
