import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment  } from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class LogsService {

  constructor(
    private _httpClient: HttpClient,
  ) { }

  public getLogs(pageNumber: number, pageSize: number, search: string): Observable<any> {
    let url = "";
    if(search) {
      url = `${environment.apiUrl}/logs?q=Body='${search}'&pageNumber=${pageNumber}&pageSize=${pageSize}`
    } else {
      url = `${environment.apiUrl}/logs?pageNumber=${pageNumber}&pageSize=${pageSize}`
    }
    return this._httpClient.get<any>(url);
  }
}
