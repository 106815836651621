import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-top-bar-select',
  templateUrl: './top-bar-select.component.html',
  styleUrls: ['./top-bar-select.component.scss']
})
export class TopBarSelectComponent  {
  @Input() public link: string = '';
  @Input() public label: string = '';
  @Input() public isSelected: boolean = false;
  public selected: boolean = false;
  constructor() { }


  public selectNav(e: Event) {
    if (!this.selected) {
      e.stopPropagation();
      this.selected = true;
    }
  }

  public clickOutside() {
    this.selected = false;
  }
}
