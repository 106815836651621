<div class='list'>
  <div id="table">
    <table>
      <thead>
      <tr>
        <th *ngFor="let row of table.rows">
          {{ row.name | translate }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let rule of data; let rowIndex = index" (click)="openDetailsModal(detailsModal, rowIndex)">
        <td *ngFor="let row of table.rows" >
          <div *ngIf='row?.type!== "action" && row?.type !== "date" && row?.type !== "shortName"' (click)='emitEvent(rule, rowIndex)' [ngClass]='"table-" + rule[row?.value]' [class.status-badge]='row.value === "status"' class='table-content m-0'>{{ rule[row.value] | translate }}  {{row?.type}}</div>
          <div class='d-flex' *ngIf='row?.type === "date"'>
            {{ rule[row.value] | date }}
          </div>
          <div class='d-flex shortName' *ngIf='row?.type === "shortName"'>
            {{ rule[row.value] }}
          </div>
          <div class='d-flex' *ngIf='row?.type === "action"'>
            <mat-icon class='material-icons-outlined icon icon-edit' (click)='emitEvent(rule, rowIndex, "edit"); $event.stopPropagation()'>edit</mat-icon>
            <mat-icon class='material-icons-outlined icon icon-delete' (click)='emitEvent(rule, rowIndex, "delete"); $event.stopPropagation()'>delete</mat-icon>
            <mat-icon *ngIf='row.isSendButton' class='material-icons-outlined icon icon-edit' (click)='emitEvent(rule, rowIndex, "send"); $event.stopPropagation()'>send</mat-icon>
            <mat-icon *ngIf='row.isScheduleButton' class='material-icons-outlined icon icon-edit' (click)='emitEvent(rule, rowIndex, "schedule"); $event.stopPropagation()'>shuffle</mat-icon>
          </div>
        </td>
      </tr>
      <ng-container *ngIf='!data.length'>
        <tr *ngFor="let rule of [1,2,3,4,5,6,7,8,9,10]">
          <td *ngFor="let row of table.rows" >
            <div class="line loading-shimmer"></div>
          </td>
        </tr>
      </ng-container>
      </tbody>
    </table>
  </div>
  <app-pagination (changePage)="onPageChange($event)" [availablePagesAmount]='availablePagesAmount'></app-pagination>
</div>

<ng-template #detailsModal>
  <div class="details-modal">
    <div class="container-fluid mw-100 p-0">
      <div class="row align-items-center">
        <div class="col-md-12">
          <table class="w-100 view-table">
            <tbody>
              <tr *ngFor="let item of data[rowIndex]  | keyvalue">
                <td>{{ item.key }}</td>
                <td *ngIf="item.key !== 'FilterClauseConditions'">{{ item.value }}</td>
                <td *ngIf="item.key === 'FilterClauseConditions' ">
                  <textarea class="viewTextArea" [innerHTML]="item.value | prettyPrint" readonly rows="6"></textarea>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</ng-template>
