import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { UtilService } from '@services/util.service'
import { Router } from '@angular/router'
import {ScheduleService} from "@services/schedule.service";
import { IScheduleItems} from "@interface/schedule.modal";
import {MatDialog} from "@angular/material/dialog";
import {ModalComponent} from "../../component/modal/modal.component";
import {NotifierServices} from "@services/notification.service";

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit {
  @ViewChild('template', {static: false}) template!: TemplateRef<any>;
  @ViewChild('scheduleMessage', {static: false}) scheduleMessage!: TemplateRef<any>;
  public schedulerData: any = {};
  public isButtonLoader = false;
  public tableActionType = "";
  public availablePagesAmount!: number;
  public SchedulerID: number | string = "add";
  scheduleListPageConfig = {
    pageNumber: 1,
    pageSize: 10
  };
  public searchOption: Array<string> = []
  public breadcrumbsUrl: Array<{name: string, url: string}> = [
    {
      name: "HOME",
      url: "/dashbooard"
    },
    {
      name: "SCHEDULE_LIST",
      url: "/schedule"
    }
  ];
  public scheduleTable = {
    rows : [
      {
        name: "NAME",
        value: "SchedullerTitle"
      },
      {
        name: "SEND_TIME",
        value: "SendTime"
      },
      {
        name: "SEPARATION_COUNT",
        value: "SeparationCount"
      },
      {
        name: "SEND_TIME",
        value: "clicks"
      },
      {
        name: "START_DATE",
        value: "StartDate",
        type: "date"
      },
      {
        name: "FINISH_DATE",
        value: "EndDate",
        type: "date"
      },
      {
        name: "ACTION",
        value: "action",
        type: "action",
        isSendButton: true
      }
    ]
  };
  public searchString = "";
  public dialogRef: any;
  public scheduleTableData: Array<IScheduleItems> = []
  constructor(
    readonly UtilService: UtilService,
    readonly router: Router,
    readonly scheduleService: ScheduleService,
    readonly dialog: MatDialog,
    readonly notificationService: NotifierServices
  ) { }

  ngOnInit(): void {
    this.getAllSchedule();
  }
  public print(): void {
    this.UtilService.printElement('table');
  }
  public exportCSV(): void {
    this.UtilService.exportToCSV(this.scheduleTableData, "Schedule");
  }

  public getScheduleEvent(event: any): void {
    this.tableActionType = event.type;
    this.SchedulerID = event.data.ID_Scheduller
    if (event.type === "edit") {
      this.editSchedule();
    } else if (event.type === "send") {
      this.schedulerData = event.data;
      this.dialogRef = this.dialog.open(ModalComponent, {
        data: { title: "SEND_SCHEDULE", template: this.template, isShowHeader: true },
        height: "auto",
        width: "450px",
        autoFocus: false,
      });
      this.dialogRef.afterClosed().subscribe(() => {
        this.isButtonLoader = false;
      })
    } else if(event.type === "delete") {
      this.dialogRef = this.dialog.open(ModalComponent, {
        data: { title: "DELETE_SCHEDULE", template: this.template, isShowHeader: true },
        height: "auto",
        width: "450px",
        autoFocus: false,
      });
    }
  }

  public getAllSchedule(): void {
    this.scheduleService.getSchedulesDetails(this.scheduleListPageConfig.pageNumber, this.scheduleListPageConfig.pageSize, this.searchString).subscribe(schedule => {
      this.availablePagesAmount = schedule.totalPages
      this.scheduleTableData = schedule.items
      schedule.items.forEach(element => {
        this.searchOption.push(element.SchedullerTitle)
      });
    })
  }

  public onPageChange(pageNumber: number): void {
    this.scheduleListPageConfig.pageNumber = pageNumber;
    this.getAllSchedule();
  }

  public onPageLimitChange(pageSize: number): void {
    this.scheduleListPageConfig.pageNumber = 1;
    this.scheduleListPageConfig.pageSize = pageSize;
    this.getAllSchedule();
  }

  public deleteSchedule(): void {
    this.scheduleService.deleteScheduleDetails(this.SchedulerID).subscribe(() => {
      this.notificationService.showNotification("success", "Schedule deleted successfully")
      this.dialogRef.close();
      this.getAllSchedule();
    }, error => {
      this.notificationService.showNotification("error", error.error.message? error.error.message : "Something went wrong")
      this.dialogRef.close();
    })
  }

  public runSchedule(): void {
    this.isButtonLoader = true;
    const sendMessageObj = {
      ID_Scheduller: this.schedulerData.ID_Scheduller,
      ID_Message: this.schedulerData.ID_Message,
      ID_Sender: parseInt(`${localStorage.getItem("senderID")}`),
      FilterClauseConditions: JSON.parse(this.schedulerData.FilterClauseConditions)
    }
    this.scheduleService.sendMessageNew(sendMessageObj).subscribe(result => {
      this.isButtonLoader = false;
      this.notificationService.showNotification("success", result.message);
      this.dialogRef.close();
    }, error => {
      this.isButtonLoader = false;
      this.notificationService.showNotification("error", error.error.message? error.error.message : "Something went wrong")
      this.dialogRef.close();
    })
  }
  public getSearchEvent(event: any) {
    this.scheduleListPageConfig.pageNumber = 1;
    this.scheduleListPageConfig.pageSize = 10;
    this.searchString = event;
    this.getAllSchedule();
  }

  public editSchedule() {
    this.dialogRef = this.dialog.open(this.scheduleMessage, {
      height: "auto",
      width: "75%",
      autoFocus: false,
    });
  }

  closeModel() {
    this.dialogRef.close();
  }
}
