import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment  } from '../../environments/environment'
import {IScheduleInterface, IScheduleItems} from '@interface/schedule.modal';
import {UtilService} from "@services/util.service";

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  constructor(
    private _httpClient: HttpClient,
    private utilService: UtilService
  ) { }

  public getSchedulesDetails(pageNumber: number, pageSize: number, search: string): Observable<IScheduleInterface> {
    let url = "";
    if(search) {
      url = `${environment.apiUrl}/senders/${this.utilService.getSenderId()}/schedules?q=SchedullerTitle='${search}'&pageNumber=${pageNumber}&pageSize=${pageSize}`
    } else {
      url = `${environment.apiUrl}/senders/${this.utilService.getSenderId()}/schedules?pageNumber=${pageNumber}&pageSize=${pageSize}`
    }
    return this._httpClient.get<IScheduleInterface>(url);
  }

  public getScheduleDetails(scheduleId: number): Observable<Array<IScheduleItems>> {
    const url = `${environment.apiUrl}/senders/${this.utilService.getSenderId()}/schedules/${scheduleId}`
    return this._httpClient.get<Array<IScheduleItems>>(url);
  }

  public sendMessageNew(body: any): Observable<any> {
    const url = `${environment.apiUrl}/messages/send`;
    return this._httpClient.post<any>(url, body);
  }

  public patchScheduleDetails(data: IScheduleInterface, scheduleId: number): Observable<IScheduleInterface> {
    const url = `${environment.apiUrl}/senders/${this.utilService.getSenderId()}/schedules/${scheduleId}`
    return this._httpClient.patch<IScheduleInterface>(url, data);
  }

  public postScheduleDetails(data: any): Observable<any> {
    const url = `${environment.apiUrl}/senders/${this.utilService.getSenderId()}/schedules`
    return this._httpClient.post<any>(url, data);
  }

  public deleteScheduleDetails(scheduleId: number | string): Observable<any> {
    const url = `${environment.apiUrl}/senders/${this.utilService.getSenderId()}/schedules/${scheduleId}`
    return this._httpClient.delete<any>(url);
  }
}
