<header class='header'>
  <div class='top-header'>
    <div class='top-header-inner d-flex align-items-center'>
      <div class='top-logo'>
        <a routerLink='/' class='d-flex align-items-center'>
          <img src='assets/images/logo.png' class='img-fluid' alt='appy logo' width='100'>
        </a>
      </div>
      <div class='top-navigation'>
        <app-top-bar-navigation-list></app-top-bar-navigation-list>
      </div>
      <div class='right-part ms-auto me-5 me-lg-0'>
        <div class='d-flex align-items-center'>
          <app-language-selector></app-language-selector>
          <mat-select [(ngModel)]="senderID" class="select-width ps-2" (ngModelChange)="setSenderID($event)">
            <mat-option *ngFor="let sender of senders" [value]="sender.ID_Senders" >
              {{sender.Name}}
            </mat-option>
          </mat-select>
          <div class='notification ps-2'>
            <mat-icon class='material-icons-outlined'> notifications</mat-icon>
          </div>
          <app-profile-widget></app-profile-widget>

        </div>
      </div>
    </div>
  </div>
</header>
