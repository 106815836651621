import { Component, OnInit } from '@angular/core';
import { IAccountInterface } from '@interface/account.modal';
import { UtilService } from '@services/util.service';
import { AccountService } from '@services/account.service';
import { NotifierServices } from '@services/notification.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss', '../../component/input/input.component.scss']
})
export class ProfileComponent implements OnInit {
  public isShowTitle: boolean = false;
  public profileLanguage: Array<{name: string, value: string}> = [
    {name: "EN", value: "English"},
    {name: "IN", value: "Portuguese"},
    {name: "AO", value: "Dutch"},
    {name: "AU", value: "Dutch"},
  ];
  public profileData: IAccountInterface = {
      ID_Accounts: 2,
      Name: "Yappy",
      CountryISO: "AO",
      LogoURL: "assets/images/logo.png",
      BrandColour: "#F0750F",
      AccountKey: "6E856B12-E5C4-4174-AEB2-8FB7BEA10A88",
      AuthToken: "E84A0573-0677-4BE0-910F-33C4B401D26B",
      Enabled: false,
      CreatedDate: "2022-03-04T11:04:47.613",
      UpdatedDate: "2022-03-04T11:04:47.613"
    }

  constructor(
    readonly utilService: UtilService,
    readonly accountService: AccountService,
    readonly notificationService: NotifierServices
  ) { }

  ngOnInit(): void {
    this.getAccountDetails();
  }

  public getColor(event: string): void {
    this.profileData.BrandColour = event;
    document.documentElement.style.setProperty("--primary-color", `${event}`);
    document.documentElement.style.setProperty("--primary-color-light", `${event}10`);
  }

  fileUploadEvent(event: any, uploadType: string): void {
    let file;
    if (uploadType === "drag"){
      file = event[0]
    } else {
      file = event.target.files[0];
    }
  }

  copyToClipboard(value: string) {
    this.utilService.copyToClipboard(value);
  }

  public getAccountDetails(): void {
    this.accountService.getAccountDetails("123456").subscribe((res: IAccountInterface) => {
      this.profileData = res;
    })
  }

  updateAccountProfile() {
    this.accountService.updateAccountDetails("123456", this.profileData).subscribe((res: IAccountInterface) => {
      this.notificationService.showNotification("success", "Account Update Successfully.")
    })
  }
}
