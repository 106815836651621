<div class='position-relative'>
  <button class='avatar language-button p-0' type='button' (click)='togglePanel()'>
    <p class='avatar-name d-sm-block'>{{ selectLanguage | translate}}</p>
    <mat-icon>arrow_drop_down</mat-icon>
  </button>

  <button class='btn-back' type='button'  *ngIf='!isCollapsed'  (click)='togglePanel()'></button>

  <div  class='menu-wrap' *ngIf='!isCollapsed'>
    <div class='menu'>
      <div class='language' (click)="setLanguage('en')">
        <img class='avatar-img' src='assets/images/en.png' alt='avatar' />
        <p class='avatar-name d-sm-block'>{{ 'English' | translate}}</p>
      </div>
      <div class='language' (click)="setLanguage('pt')">
        <img class='avatar-img' src='assets/images/pt.jpg' alt='avatar' />
        <p class='avatar-name d-sm-block'>{{ 'Portuguese' | translate}}</p>
      </div>
    </div>
  </div>
</div>
