<div *ngIf='showUser' class='position-relative'>
  <button class='avatar profile-widget__toggle-btn' type='button' (click)='toggle()'>
    <img class='profile-widget__user-avatar' [src]='user.ProfileImgUrl? user.ProfileImgUrl : "assets/images/profile-img.png"' >

    <p class='avatar-name d-sm-block'>{{ user.name}}</p>
    <mat-icon>arrow_drop_down</mat-icon>
  </button>
  <button class='btn-back' type='button' *ngIf='!isCollapsed' (click)='toggle()'></button>
  <div class='menu-wrap'  *ngIf='!isCollapsed'>
    <div class='menu'>
        <app-sidebar-link icon='account_circle' [route]='["/users/add"]' (hideSidebarHandler)='toggle()'>
              {{ 'ADD_USER' +
        '' | translate }}
        </app-sidebar-link>
        <app-sidebar-link icon='account_circle'  [route]='profileLink' (hideSidebarHandler)='toggle()'>
            {{ 'Manage_users' | translate }}
        </app-sidebar-link>
        <app-sidebar-link icon='logout' (hideSidebarHandler)='toggle(); logout()' [stopNavigation]="true">
          {{ 'Log_Out' | translate }}
        </app-sidebar-link>
    </div>
  </div>
</div>
<div *ngIf='!showUser'>
  <button class='avatar profile-widget__toggle-btn' type='button' (click)='goToLogin()'>
    <p class='avatar-name d-sm-block login-button text-white px-3'>{{ 'Login' | translate }}</p>
  </button>
</div>

<ng-template #changeProfile>
  <app-profile></app-profile>
</ng-template>
