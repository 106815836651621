import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar-link',
  templateUrl: './sidebar-link.component.html',
  styleUrls: ['./sidebar-link.component.scss']
})
export class SidebarLinkComponent {
  @Input() public route = [''];
  @Input() public filter = {};
  @Input() public icon = '';
  @Input() public newLink = false;
  @Input() public stopNavigation = false;
  @Input() public title = '';
  @Output() public hideSidebarHandler = new EventEmitter<boolean>();
  constructor(private router: Router) {

  }


  public hideSidebar(): void {
    if (!this.stopNavigation ) {
      this.router.navigate(this.route).catch();
    }
    this.hideSidebarHandler.emit(true);
  }

}
