import { Injectable } from '@angular/core';
import {  NotifierService } from 'angular-notifier'
@Injectable({
  providedIn: 'root'
})
export class NotifierServices {
  private notifier: NotifierService;
  constructor( notifier: NotifierService ) {
    this.notifier = notifier;

  }
  public showNotification(type: "success" | "error" | "Warning" | "info", message: string ): void {
    this.notifier.notify( type, message );
    setTimeout(() => {
      this.hideAllNotifications();
    }, 3000)
  }
  public hideAllNotifications(): void {
    this.notifier.hideAll();
  }
}
